import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import { useState } from "react";

//style
import css from "../../genericMagneticStyle.less"
import i18n from "../../nls/root/i18n";

const [HbrDrawer] =
    reactWrapper(["hbr-drawer"]);

const HelpDrawer = ({ openHelpDrawer }) => {
    const [open, setOpen] = useState(true);
    const pathsArray = location.pathname.split("/");

    const handleCloseClick = () => {
        setOpen(false);
        openHelpDrawer(false);
    }
    const handleDrawerShow = () => {
        setOpen(true);
    }
    return (
        <div className={css["help-drawer"]}>
            <HbrDrawer
                scrollAware={true}
                open={open}
                id={"drawer-id"}
                onHbr-hide={handleCloseClick}
                onHbr-show={handleDrawerShow}

            >
                {/* Title */}
                <div slot="label" className="hbr-css__layout-col-2xs">
                    <div className="hbr-css__text-heading-xl-bold">{i18n.help}</div>
                    <div className="hbr-css__text-secondary-sm hbr-type-body3 subtitle">
                        {pathsArray[pathsArray.length - 1]}
                    </div>
                </div>

                {/* Content */}
                <div
                    className="hbr-css__container-empty"
                >
                    {i18n.helpNoContent}
                </div>
            </HbrDrawer>
        </div>
    )
}

HelpDrawer.propTypes = {
    openHelpDrawer: PropTypes.func
}
export default HelpDrawer;
