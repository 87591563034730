import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { CaretDown, User, Users, UserCircle } from "phosphor-react";
import "@magnetic/cross-platform-navigator";
import { PropTypes } from "prop-types";

//i18n files
import i18n from "amdi18n-loader!../../nls/i18n";

//utils
import { deleteCookies, getCookie } from "../../../serviceWorker";
import { removeRumUser } from "../../../monitoring";
import { userProfileStyles } from "../appHeaderConfig";
import { removeItemsLocalStorage, removeLocalStorageFlag } from "../../../utils/common";

//config
import apiService from "../../../config/api-config";
import { BASE_URL, PROFILE_URL } from "../../../apis/apiConstants";

//style
import css from "../../genericMagneticStyle.less";

const [HbrButton, HbrDropdown] =
    reactWrapper(["hbr-button", "hbr-dropdown"]);

const UserProfile = ({ loggedInUser, waffleRef }) => {
    const clUsername = getCookie("cl-user");
    const isUIconvergence = getCookie("cl-analytics");

    // This "Admin" user name hardcoded for cypress localhost setup
    const userName = window.location.host.includes("localhost")
        ? "Admin"
        : loggedInUser?.includes("@")
            ? loggedInUser.split("@")[0]
            : "";

    const navigateToProfile = () => {
        window.location.href = PROFILE_URL;
    }

    const removeCookies = () => {
        removeItemsLocalStorage();
        removeLocalStorageFlag("user_email");
        removeLocalStorageFlag("availableFeatures");
        removeLocalStorageFlag("okta-shared-transaction-storage");
        removeRumUser();
        deleteCookies();
        sessionStorage.removeItem("overlays");
        sessionStorage.removeItem("okta-transaction-storage");
    }

    const logout = async (e) => {
        if (isUIconvergence) {
            //below block is for UI convergence

            try {
                let res = await apiService.logoutVManage();

                console.log("Response for logout", res);

                window.sessionStorage.removeItem('hasShownEula');
                window.localStorage.removeItem('accessToken');
                window.localStorage.removeItem('authCode');
                window.location.href = '/login.html';

                removeCookies();
                e.preventDefault();

            } catch (err) {
                console.log("Error while calling logout", err);
            }
        } else {
            //below block is for default vA and cross launch
            try {
                const logoutCookie = getCookie("lg-vManageURI");

                let res = await apiService.logout();

                removeCookies();
                e.preventDefault();

                if (res) {
                    if (logoutCookie) {
                        window.location.href = logoutCookie;
                    } else if (res.status === 200) {
                        window.location.href = `${BASE_URL}/login`;
                    }
                }
            } catch (err) {
                console.log("Error while calling logout", err);
            }
        }
    };

    return (
        <>
            <HbrDropdown
                slot="user-profile"
                hoist
                menu
                sentiment="header"
                distance="12"
            >
                <HbrButton noPadding sentiment="header" slot="trigger">
                    <User slot="prefix" size={22} weight="bold" />
                    <CaretDown slot="suffix" size={22} weight="bold" />
                    <div style={userProfileStyles.labelBold}>
                        {clUsername !== undefined ? clUsername.replace(/['"]/g, '') : userName.replace(/['"]/g, '')}
                    </div>
                </HbrButton>
                <div className={css["profile"]} style={userProfileStyles.menu}>
                    {/*Logged as*/}
                    <div style={userProfileStyles.menuItem}>
                        <User style={userProfileStyles.prefix} size={20} weight="bold" />
                        <div style={userProfileStyles.menuBody}>
                            <div style={userProfileStyles.heading}>{i18n.headerLoggedLabel}</div>
                            <div style={userProfileStyles.labelBold}>
                                {clUsername !== undefined ? clUsername.replace(/['"]/g, '') : userName.replace(/['"]/g, '')}
                            </div>
                            {!isUIconvergence && <div style={userProfileStyles.label}>
                                {loggedInUser !== undefined ? loggedInUser : ""}
                            </div>}
                        </div>
                        <HbrButton
                            variant="outline"
                            onClick={e => logout(e)}
                            className={"suffix"}
                            noPadding
                        >
                            {i18n.headerLogOutLabel}
                        </HbrButton>
                    </div>
                    {/*Role as*/}
                    {isUIconvergence &&
                        <div style={userProfileStyles.menuItem}>
                            <Users style={userProfileStyles.prefix} size={20} weight="bold" />
                            <div style={userProfileStyles.menuBody}>
                                <div style={userProfileStyles.heading}>{i18n.headerRoleAs}</div>
                                <div style={userProfileStyles.labelBold}>
                                    {clUsername !== undefined ? clUsername.replace(/['"]/g, '') : userName.replace(/['"]/g, '')}
                                </div>
                            </div>
                        </div>}
                    {/*My Profile*/}
                    {isUIconvergence &&
                        <div style={userProfileStyles.menuItem} onClick={navigateToProfile}>
                            <UserCircle style={userProfileStyles.prefix} size={20} weight="bold" />
                            <div style={userProfileStyles.menuBody}>
                                <div className="hbr-type-body3 header-space">{i18n.headerMyProfile}</div>
                            </div>
                        </div>}
                </div>
            </HbrDropdown >
            {waffleRef.current.isWaffleShown && <cross-platform-navigator></cross-platform-navigator>}
        </>
    );
}

UserProfile.propTypes = {
    waffleRef: PropTypes.object,
    loggedInUser: PropTypes.string
}

export default UserProfile;