export const userProfileStyles = {
  heading: {
    fontSize: "10px",
    color: "var(--hbr-color-text-weak)",
    padding: "0 12px"
  },
  labelBold: {
    fontWeight: "600",
    textAlign: "left",
    lineHeight: "12px",
    padding: "0 12px"
  },
  label: {
    fontWeight: "400",
    textAlign: "left",
    lineHeight: "12px",
    padding: "0 12px"
  },
  menuBody: {
    padding: "12px",
    flex: "1 1 auto"
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    minWidth: "fit-content",
    maxWidth: "fit-content",
    boxShadow: "var(--hbr-shadow-xs)",
    borderRadius: "6px",
    border: "2px solid var(--hbr-color-neutral-border, #889099)",
    backgroundColor: "var(--background, #ffffff)"
  },
  menuItem: {
    backgroundColor: "var(--background)",
    position: "relative",
    display: "flex",
    alignItems: "center",
    fontFamily: "var(--hbr-font-body)",
    fontSize: "var(--hbr-font-size-sm)",
    fontWeight: "var(--hbr-font-weight-normal)",
    lineHeight: "var(--hbr-font-line-height-normal)",
    letterSpacing: "var(--hbr-font-letter-spacing-normal)",
    textAlign: "left",
    color: "var(--hbr-control-color, #23282e)",
    padding: "var(--hbr-spacing-2xs) var(--hbr-spacing-md)",
    transition: "var(--hbr-transition-fast) fill",
    userSelect: "none",
    whiteSpace: "nowrap",
    borderBottom: "1px solid var(--hbr-color-gray-90, #E1E4E8)"
  },
  prefix: {
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center"
  },
  devider: {
    borderTop: "solid 1px var(--hbr-color-neutral-border-weak)",
    margin: "var(--hbr-spacing-xs) 0"
  }
};