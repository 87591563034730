import React, { useState, useEffect, useMemo } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { matchPath } from "react-router";
import { BASE_URL } from "../../apis/apiConstants";
//style
import css from "../genericMagneticStyle.less";

//utils
import { getFilterItems, isOverviewPage, setLocalStorageFlag } from "../../utils/common";
import AppHoc from "../AppHOC";
import timeFilterConfig from "../config/time-filter-config";
import { isCustom, getStoredTimePeriod } from "../../utils/common";
import ApiService from "../../config/api-config";
import { getApplicationsTableList } from "../../utils/views/applications";
import {
  subtractUnitsFromDate,
  addUnitsFromDate,
  formatDate,
  getPeriod,
  getDiffBtwDates,
  shortDisplayDateFullYear,
  prevDay,
  getTimePeriodELT,
  getStartOfDayUTC,
  getEndOfDayUTC,
  getStartfDayUTCFormat
} from "../../utils/displayTime";
import { useMount } from "../../utils/genericCommon";
import { updateRumCustomTimeRange } from "../../monitoring";
import { updateCookie } from "../../utils/common";
import { getCookie } from "../../serviceWorker";
import { FEATURE_CONFIG } from "../../utils/enums";
import img from "../../assets/Beta.svg";

const [HbrSelect, HbrIcon, HbrOption, HbrDatePicker] = reactWrapper([
  "hbr-select",
  "hbr-icon",
  "hbr-option",
  "hbr-date-picker",
]);

/**
 * Time Filter
 * Displays time selection options in dropdown,
 * along with timestamp date comparison
 *
 * See defaultProps for example
 *
 * Usage:
 *      <TimeFilter options={} />
 */

const TimeFilter = ({
  globalFilter,
  location,
  setCtrlTimeFilter,
  setPathAnalyticsTab,
  setSideBarTimeFilter,
  sideBar,
  hideDropdown,
  setAppsWithFamilySLA,
  setAggregateAppData,
  options,
  setCustomTime,
  setSideBarCustomTime
}) => {
  const isCustomDateShown = false;
  const timeFilter = isCustom(globalFilter.timePeriodSelected) ? globalFilter.timePeriodSelected : getStoredTimePeriod();
  const [range, setRange] = useState(
    globalFilter.selectedCustomTime ? globalFilter.selectedCustomTime.range : {}
  );
  const [timePeriodSelected, setTimePeriodSelected] = useState(
    timeFilter ? timeFilter : "1"
  );
  let lastDisplayDate = prevDay();
  const [maxDate, setMaxDate] = useState(lastDisplayDate);
  const [dateErrorState, setDateErrorState] = useState(false);
  const mount = useMount();
  const uiconvergenceCookie = getCookie("cl-analytics");

  useEffect(() => {
    if (isCustom(timePeriodSelected) && document.querySelector(".timestamp"))
      document.querySelector(".timestamp").style.visibility = "hidden";
    if (sideBar) {
      setSideBarTimeFilter(
        {
          current_period: globalFilter.timeFilter.current_period,
          previous_period: globalFilter.timeFilter.previous_period
        },
        timePeriodSelected
      );
    }
  }, []);

  /****if sitesdropdwon selected to all sites the pa tab set false in reducer  */
  useEffect(() => {
    if (globalFilter.selectedSite === -1) {
      setPathAnalyticsTab(false);
    }
  }, [globalFilter.selectedSite]);

  const callApplicationList = () => {
    /* This will call custom list api whenever we change site from site filter */
    if (!globalFilter.overlay_reload_triggered) {
      let promisesQueue = [];
      const defaultRequest = { ...globalFilter.globalV4Payload };
      defaultRequest.sort = { usage: "desc" };
      promisesQueue.push(
        ApiService.getTableCustomList(defaultRequest)
      );

      setAppsWithFamilySLA(undefined);

      Promise.all([...promisesQueue]).then(aggregateApplicationsResults => {
        setAggregateAppData(aggregateApplicationsResults[0]);
        let tableList = getApplicationsTableList(
          aggregateApplicationsResults[0].data
        );
        setAppsWithFamilySLA(tableList);
      });
    }
  };
  useMemo(() => {
    // prepareQuery for time filter
    setMaxDate(lastDisplayDate);
    if (!mount.initMount) {
      callApplicationList(globalFilter.timeFilter);
    } else {
      mount.initMount = false;
    }
  }, [globalFilter.globalV4Payload.entry_ts.end,
  globalFilter.globalV4Payload.entry_ts.start]);

  const applyCustomTime = (startDate, endDate) => {
    const startCustomTime = formatDate(startDate);
    //endCustomTime becomes starting point for time as current time
    const endCustomTime = formatDate(endDate);
    const startCustomTimeStamp = getStartfDayUTCFormat(new Date(startDate));
    const endCustomTimeStamp = getStartfDayUTCFormat(new Date(endDate));
    const diffInDays = endCustomTime.diff(startCustomTime, "days");
    const customRange = {
      start: { date: startCustomTimeStamp },
      end: { date: endCustomTimeStamp }
    };

    const timePeriod = {
      current_period: [
        startDate,
        endDate
      ],
      previous_period: [
        getPeriod(endCustomTime, "days", diffInDays * 2, "days"),
        getPeriod(endCustomTime, "days", diffInDays * 24 * 60 + 1, "minutes")
      ]
    };

    const currTimePeriod = {
      current_period: timePeriod.current_period,
      previous_period: timePeriod.previous_period
    };
    if (sideBar) {
      setSideBarCustomTime({
        selectedCustomTime: { ...currTimePeriod },
        ...currTimePeriod,
        range: customRange,
        timePeriodSelected: timePeriodSelected
      });
      setSideBarTimeFilter(timePeriod, timePeriodSelected);
    } else {
      setCustomTime({
        ...currTimePeriod,
        sideBarTimeFilter: { ...currTimePeriod },
        range: customRange
      });

      setCtrlTimeFilter(timePeriod, timePeriodSelected);
    }
    updateRumCustomTimeRange([
      currTimePeriod.previous_period[0],
      currTimePeriod.current_period[1]
    ]);
  };

  const onTimeChange = e => {
    const timePeriod = getTimePeriodELT(e.currentTarget.value);

    setTimePeriodSelected(e.currentTarget.value);

    if (!isCustom(e.currentTarget.value) && !sideBar) {
      setLocalStorageFlag("timePeriodSelected", e.currentTarget.value);
      updateCookie("timePeriodSelected", e.currentTarget.value)
    }

    if (!isCustom(e.currentTarget.value)) {
      if (document.querySelector(".timestamp"))
        document.querySelector(".timestamp").style.visibility = "visible";
      setRange({});
      sideBar
        ? setSideBarTimeFilter(timePeriod, e.currentTarget.value)
        : setCtrlTimeFilter(timePeriod, e.currentTarget.value);
      updateRumCustomTimeRange(null);
    } else {
      if (document.querySelector(".timestamp"))
        document.querySelector(".timestamp").style.visibility = "hidden";

      setRange({})
      sideBar
        ? setSideBarTimeFilter(null, e.currentTarget.value)
        : setCtrlTimeFilter(timePeriod, e.currentTarget.value);
    }
  };

  const onDateChange = e => {
    if (e.currentTarget.value[0]) {
      let countOfDaysForRange = getDiffBtwDates(
        maxDate,
        e.currentTarget.value[0],
        "days"
      );

      //max range could be up to 7 days
      //if the user choose a start date within a week from today date, max range will be less then 7 day
      //else we keep max range to be 7
      if (countOfDaysForRange > 6 || countOfDaysForRange < 0) {
        countOfDaysForRange = 6;
      }

      setMaxDate(
        addUnitsFromDate(e.currentTarget.value[0], countOfDaysForRange, "days")
      );
      if (e.currentTarget.value[0] && e.currentTarget.value[1]) {
        const startDateTimestamp = getStartOfDayUTC(e.currentTarget.value[0]);
        const endDateTimestamp = getEndOfDayUTC(e.currentTarget.value[1]);
        const diff = getDiffBtwDates(
          e.currentTarget.value[1],
          e.currentTarget.value[0],
          "days"
        );

        let isDateValid = (diff < 7 && diff > 0) ? true : false;
        const startCustomTime = getStartfDayUTCFormat(new Date(startDateTimestamp));
        const endCustomTime = getStartfDayUTCFormat(new Date(endDateTimestamp));

        setRange({
          start: { date: startCustomTime },
          end: { date: endCustomTime }
        });

        setDateErrorState(false);
        isDateValid ? applyCustomTime(startDateTimestamp, endDateTimestamp) : setDateErrorState(true);
      }
    }
  };

  const renderFilter = () => {
    // Check the page is security and remove the custom time range options from time filter
    const isSecurityTabPath = matchPath(location.pathname, { path: `${BASE_URL}/threatintelligence`, exact: true });

    if (isSecurityTabPath) {
      let newOptions = {};
      for (let [key, value] of Object.entries(options)) {
        if (value.label !== 'Custom') {
          newOptions[key] = value;
        }
      }
      options = newOptions;
      if (timePeriodSelected === 'Custom') {
        const isUIconvergence = getCookie("cl-analytics");
        const defaultTimeValue = isUIconvergence ? "1" : "12";
        setTimePeriodSelected(defaultTimeValue);
        const timePeriod = getTimePeriodELT(defaultTimeValue)
        setLocalStorageFlag("timePeriodSelected", defaultTimeValue);
        sideBar
          ? setSideBarTimeFilter(timePeriod, defaultTimeValue)
          : setCtrlTimeFilter(timePeriod, defaultTimeValue);
      }
    }

    /*****Remove custom time filter when pathanalytics tab is active */

    let app360PathName = location.pathname.includes("/Dashboards");

    if (globalFilter.pathanalyticsTabView && app360PathName) {
      let newOptionsPathAnalytics = {};
      for (let [key, value] of Object.entries(options)) {
        if (value.label !== 'Custom') {
          newOptionsPathAnalytics[key] = value;
        }
      }
      options = newOptionsPathAnalytics;
      if (timePeriodSelected === 'Custom') {
        const defaultTimeValue = '12';
        setTimePeriodSelected(defaultTimeValue);
        const timePeriod = getTimePeriodELT(defaultTimeValue)
        setLocalStorageFlag("timePeriodSelected", defaultTimeValue);
        sideBar
          ? setSideBarTimeFilter(timePeriod, defaultTimeValue)
          : setCtrlTimeFilter(timePeriod, defaultTimeValue);
      }
    }

    const opts = options ? options : timeFilterConfig;
    const today = lastDisplayDate;
    let twelveWeeksBefore = subtractUnitsFromDate(today, 11, "weeks")
      .startOf("isoWeek")
      .day(0);
    let curMaxDate = maxDate;
    let curMinDate = twelveWeeksBefore;
    let placeholder = ["Start date", "End date"];

    if (range.start && range.end) {
      placeholder = [
        shortDisplayDateFullYear(range.start.date).toString(),
        shortDisplayDateFullYear(range.end.date).toString()
      ];
    }

    const hbrDate = (
      <div className="custom-date">
        <HbrDatePicker
          hoist={true}
          required={true}
          range={true}
          variant={"outline"}
          outlineLabel={"Label"}
          error={dateErrorState}
          maxDate={new Date(curMaxDate).toString()}
          minDate={new Date(curMinDate).toString()}
          showDaysInNextAndPreviousMonths={true}
          calendarStyle={"square"}
          width="116px"
          onHbr-change={evt => onDateChange(evt)}
          placeholder={placeholder}
          i18n={{
            weekdaysShort: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            months: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ]
          }}
        ></HbrDatePicker>
      </div>
    );

    return (
      <>
        {!hideDropdown && (
          <div data-cy="timeFilterDropDown" className="date-filter-dropdown">
            <HbrSelect
              className={sideBar ? "time-filter-select" : ""}
              style={{ width: FEATURE_CONFIG.TIMEFILTER.isBeta && !uiconvergenceCookie ? "180px" : "140px" }}
              value={timePeriodSelected}
              onHbr-change={onTimeChange}
            >
              <HbrIcon slot="prefix" name="clock" sentiment="neutral"></HbrIcon>
              {getFilterItems(opts).map(option => (
                <HbrOption key={option.label} value={option.value}>
                  {option.label}{" "}{option.additionalText && FEATURE_CONFIG.TIMEFILTER.isBeta && !uiconvergenceCookie &&
                    <img src={img} className={css["beta-tag"]} />}
                </HbrOption>
              ))}
            </HbrSelect>
          </div>
        )}
        {!isCustomDateShown && !isSecurityTabPath && isCustom(timePeriodSelected) && hbrDate}
      </>
    );
  };

  if (!globalFilter.isTimeFilterHidden) {
    return (
      <div className={css["time-filter"]} data-cy="timeFilter">
        <div className={isOverviewPage() ? "custom-filter" : "filter"}>{renderFilter()}</div>
      </div>
    );
  } else {
    return <></>;
  }
};

TimeFilter.defaultProps = {
  options: timeFilterConfig,
  sideBar: false,
  hideDropdown: false
};

TimeFilter.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  sideBar: PropTypes.bool,
  setSideBarTimeFilter: PropTypes.func.isRequired,
  setPathAnalyticsTab: PropTypes.func.isRequired,
  setCtrlTimeFilter: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  setSideBarCustomTime: PropTypes.func.isRequired,
  setCustomTime: PropTypes.func.isRequired,
  hideDropdown: PropTypes.bool,
  location: PropTypes.object.isRequired,
  setAggregateAppData: PropTypes.func.isRequired,
  setAppFamilySLA: PropTypes.func.isRequired,
  setAppsWithFamilySLA: PropTypes.func.isRequired
};

export default AppHoc(TimeFilter);
