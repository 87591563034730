import { PropTypes } from "prop-types";

//utils
import apiService from "../../../config/api-config";
import { deleteCookies } from "../../../serviceWorker";
import Menu from "../Menu";

const ManagerTab = (props) => {

    const vmanageOnClick = async (event, redirectUrl) => {
        try {
            event.preventDefault();
            let res = await apiService.logout();
            deleteCookies();
            sessionStorage.removeItem("overlays");
            localStorage.removeItem("availableFeatures");
            if (res) {
                window.location.href = redirectUrl;
            }
        } catch (err) {
            console.log("Error while calling logout", err);
        }
    };

    return (
        <Menu
            collapsed
            isSingleMenuItem={true}
            navItem={props.navItem}
            onClick={e => {
                vmanageOnClick(e, props.redirectUrl);
            }}
        />
    )
}

ManagerTab.propTypes = {
    navItem: PropTypes.object,
    redirectUrl: PropTypes.string
};
export default ManagerTab;