import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";

//utils
import { shortDisplayTime, updateTimeWithOffset, getStartfDayUTCFormat } from "../../utils/displayTime";
import i18nMessageBundle from "amdi18n-loader!../nls/i18n";
import timeFilterConfig from "../config/time-filter-config";
import { showComparisonText, validateTimePeriodSelected, validateTimePeriodSelectedSidebar, isSecurityPage, isCustom, getLocalStorageFlag } from "../../utils/common";

//style
import css from "../genericMagneticStyle.less";

const TimeStamp = ({ globalFilter, sideBar, options, isReport, reportData, hideCompareText }) => {
  const { timeFilter, sideBarTimeFilter, timePeriodSelected } = globalFilter;
  const isDaySavingTime = false;
  const displayComparison = showComparisonText(location);
  const [time, setTime] = useState(timePeriodSelected);
  const formatedCurrentTime = reportData?.current_period_end ?
    updateTimeWithOffset(reportData.created_by, reportData.current_period_end, isDaySavingTime)
    : timeFilter?.current_period[1];
  const formatedPrevTime = reportData?.current_period_start ?
    updateTimeWithOffset(reportData.created_by, reportData.current_period_start) :
    timeFilter?.current_period[0];
  let selectedTimeframe = sideBar ? validateTimePeriodSelectedSidebar(globalFilter) : validateTimePeriodSelected(globalFilter);
  const currentTime = shortDisplayTime(
    sideBar
      ? selectedTimeframe === "CUSTOM" ? getStartfDayUTCFormat(sideBarTimeFilter.current_period[1]) :
        sideBarTimeFilter.current_period[1]
      : selectedTimeframe === "CUSTOM" ? getStartfDayUTCFormat(timeFilter.current_period[1]) : formatedCurrentTime
  );
  const prevTime = shortDisplayTime(
    sideBar ?
      selectedTimeframe === "CUSTOM" ? getStartfDayUTCFormat(sideBarTimeFilter.current_period[0]) :
        sideBarTimeFilter.current_period[0] : selectedTimeframe === "CUSTOM" ? getStartfDayUTCFormat(timeFilter.current_period[0]) : formatedPrevTime
  );

  useEffect(() => {
    if (isSecurityPage() && isCustom(timePeriodSelected)) {
      setTime(getLocalStorageFlag("timePeriodSelected"))
    } else {
      setTime(globalFilter.timePeriodSelected);
    }
  }, [globalFilter.timePeriodSelected]);

  useEffect(() => {
    if (sideBar && globalFilter?.sideBarTimeFilter?.timePeriodSelected) {
      setTime(globalFilter?.sideBarTimeFilter?.timePeriodSelected);
    }
  }, [globalFilter.sideBarTimeFilter]);

  return (
    <div className={`${css["timestamp"]} hbr-type-body4`}>
      <span className="text">{prevTime}</span>
      {selectedTimeframe === "CUSTOM" && < span className="text">{" UTC"}</span>}
      <span className="text">{" - "}</span>
      <span className="text">{currentTime} </span>
      {selectedTimeframe === "CUSTOM" && <span className="text">{" UTC"}</span>}
      {displayComparison && !hideCompareText && !isReport && (
        <span className="text compare-label">
          {`  ${i18nMessageBundle.timeFilterCustomCompareWith}`}
          {options ? options[time].text : timeFilterConfig[time].text}
        </span>
      )}
    </div>
  );
};

TimeStamp.defaultProps = {
  options: timeFilterConfig,
  reportData: {}
};

TimeStamp.propTypes = {
  globalFilter: PropTypes.object,
  sideBar: PropTypes.bool,
  options: PropTypes.object,
  isReport: PropTypes.bool,
  reportData: PropTypes.object,
  hideCompareText: PropTypes.bool
};

export default TimeStamp;
