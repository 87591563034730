import { PropTypes } from "prop-types";

import i18n from "amdi18n-loader!../../../nls/i18n";

const ChatForm = ({ setInput }) => {
    return (
        <div className="hbr-css__chat-form">
            <hbr-textarea
                rows="1"
                placeholder={i18n.formPlaceholder}
                resize="auto"
                class="hbr-css__chat-input"
                id="chatInputTextarea"
                autocomplete
                onClick={(event) => setInput(event)}
            >
                <hbr-button variant="text" slot="suffix">
                    <hbr-icon name="paper-plane-right" size="24px" static-colors />
                </hbr-button>
            </hbr-textarea>
            <div className="hbr-css__chat-disclaimer">
                {i18n.formDisclosure}
            </div>
        </div>
    );
}
ChatForm.propTypes = {
    setInput: PropTypes.func
}
export default ChatForm;