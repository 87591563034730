import React from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { Question } from "phosphor-react";
import { PropTypes } from "prop-types";

import i18n from "amdi18n-loader!../../nls/i18n";

const [HbrButton, HbrDropdown, HbrMenu, HbrMenuItem, HbrIcon] =
    reactWrapper(["hbr-button", "hbr-dropdown", "hbr-menu", "hbr-menu-item", "hbr-icon", "hbr-link", "hbr-modal"]);

const HelpWidget = ({ openHelpModal, openHelpDrawer }) => {

    const openNewAskPage = () => {
        const link = "https://cnb.cisco.com/web";
        window.open(link, '_blank');
    }

    const openNewGuidePage = () => {
        const link = "https://www.cisco.com/c/en/us/td/docs/routers/sdwan/config/ios-xe-sdwan17.html";
        window.open(link, '_blank');
    }

    const openModal = () => {
        openHelpModal(true);
    }
    const openDrawer = () => {
        openHelpDrawer(true);
    }

    return (
        <div>
            <HbrDropdown sentiment="header" distance="12">
                <HbrButton noPadding sentiment="header" slot="trigger">
                    <Question size={24} weight="bold" />
                </HbrButton>
                <HbrMenu>
                    <HbrMenuItem
                        value="help"
                        onClick={openDrawer}
                    >
                        {i18n.help}
                    </HbrMenuItem>
                    <HbrMenuItem
                        value="helpGuide"
                        onClick={openNewGuidePage}
                    >
                        {i18n.helpUserGuide}
                        <HbrIcon name="arrow-square-out" slot="suffix" width="22px" className="help-arrow-icon" />
                    </HbrMenuItem>
                    <HbrMenuItem value="helpAsk"
                        onClick={openNewAskPage}
                    >
                        {i18n.helpAsk}
                        <HbrIcon name="arrow-square-out" slot="suffix" width="22px" className="help-arrow-icon" />
                    </HbrMenuItem>
                    <HbrMenuItem
                        value="helpAbout"
                        onClick={openModal}
                    >
                        {i18n.helpAbout}
                    </HbrMenuItem>
                </HbrMenu>
            </HbrDropdown>
        </div>
    )
}

HelpWidget.propTypes = {
    openHelpModal: PropTypes.func,
    openHelpDrawer: PropTypes.func
}

export default HelpWidget;