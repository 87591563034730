export const ACTIONS = {
  SET_OVERLAY_ID: "SET_OVERLAY_ID",
  SET_CTRL_TIME_FILTER: "SET_CTRL_TIME_FILTER",
  SET_CUSTOM_TIME: "SET_CUSTOM_TIME",
  SET_WORKFLOW_STATE: "SET_WORKFLOW_STATE"
};

export const setOverlayId = overlay => ({
  type: ACTIONS.SET_OVERLAY_ID,
  payload: overlay
});

export const setCtrlTimeReportFilter = (
  time_period,
  timePeriodSelected
) => dispatch => {
  dispatch({
    type: ACTIONS.SET_CTRL_TIME_FILTER,
    payload: {
      time_period,
      timePeriodSelected
    }
  });
};

export const setCustomReportTime = time => ({
  type: ACTIONS.SET_CUSTOM_TIME,
  payload: time
});

export const setWorkflow = flow => ({
  type: ACTIONS.SET_WORKFLOW_STATE,
  payload: flow
});
