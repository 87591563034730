export const suggestions = [
    {
        "intent": "What's New ?",
        "description": "Introduction of new capabilities"
    },
    {
        "intent": "Help me troubleshoot",
        "description": "",
        "ApiGwRequired": true
    },
    {
        "intent": "How is the fabric health ?",
        "description": ""
    },
    {
        "intent": "How is the applications health ?",
        "description": ""
    }
]