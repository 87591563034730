
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import _ from "lodash";

//utils
import { getIconName } from "../../../utils/common";
import { BASE_URL } from "../../../apis/apiConstants";
import { getCookie } from "../../../serviceWorker";

//asset 
import { ReactComponent as Administrator } from "../../../assets/navv.svg";

//i18n files
import i18n from "amdi18n-loader!../../nls/i18n";

//component
import { useHistory } from "react-router-dom";
import { isPredictiveNetworksPage } from "../../../utils/common";
import BetaTag from "../../../common/BetaTag";
import { FEATURE_CONFIG } from "../../../utils/enums";

const Menu = ({ navItem, onClick, isSingleMenuItem }) => {
    const [
        HbrIcon,
        HbrMenu,
        HbrNavItem,
        HbrMenuItem,
        HbrMenuLabel
    ] = reactWrapper([
        "hbr-icon",
        "hbr-menu",
        "hbr-nav-item",
        "hbr-menu-item",
        "hbr-menu-label"
    ]);
    const clVmanageURI = getCookie("cl-analytics");
    const history = useHistory();
    const isChecked = !_.isEmpty(navItem.path) && location.pathname.includes(navItem.path);

    let MenuItemsComponent = [];
    let isvManagePath = clVmanageURI ? true : false;

    const handleMenuClick = (path, isvManagePath = false, isvAnalyticsPath = false, event) => {
        if (event?.currentTarget?.textContent === "SD-WAN Manager") {
            // case for cross launch
            onClick(event);

        } else if (isvManagePath && !isvAnalyticsPath) {
            //case for converge ui
            window.location.href = path;
        } else {
            //case for non converge ui and cross launch
            history.push(path);
        }
    }

    if (navItem.submenu) {
        const submenu = navItem.submenu;
        const submenuComponent = [];
        let isHeaderLabelFound = false;

        if (navItem.submenu.length === 1) {
            const path = isvManagePath ? `/#/app/${navItem.text.toLowerCase()}` : navItem.path;

            //Use case 1:  one menu item, like Explore or Reports menu
            MenuItemsComponent = (
                <HbrMenu shellNav className="menu-item">
                    <HbrMenuItem
                        shellNav
                        value={submenu[0].text}
                        checked={isChecked}
                        checkMark
                        onClick={(e) => handleMenuClick(path, isvManagePath, false, e)}
                        key={Math.random()}
                    >
                        {submenu[0].text}
                    </HbrMenuItem>
                </HbrMenu>)
        } else {
            //Use case 2: menu with one or more sections like Workflow menu 
            for (let submenuIndex = 0; submenuIndex < submenu.length; submenuIndex++) {
                const sectionIndex = submenuIndex;

                if (submenu[submenuIndex].headerLabel) {
                    const className = navItem.text === "Workflows" ? "submenu-section-workflow" : "submenu-section-config";
                    isHeaderLabelFound = true;

                    //create a menu section with header
                    submenuComponent.push(<HbrMenuLabel shellNav className="section-label">{submenu[sectionIndex].text}</HbrMenuLabel>)

                    for (let sectionWithHeaderIndex = submenuIndex + 1; sectionWithHeaderIndex < submenu.length; sectionWithHeaderIndex++) {
                        if (submenu[sectionWithHeaderIndex].headerLabel === true) {
                            break;
                        }

                        submenuComponent.push(
                            <HbrMenuItem
                                shellNav
                                value={submenu[sectionWithHeaderIndex].text}
                                onClick={() => handleMenuClick(`/#/${submenu[sectionWithHeaderIndex].sref?.replaceAll(".", "/")}`, isvManagePath)}
                                key={Math.random()}
                            >
                                {submenu[sectionWithHeaderIndex].text}
                            </HbrMenuItem>
                        )
                        submenuIndex++;
                    }

                    // append section with header to menu
                    if (submenu.length === submenuComponent.length)
                        MenuItemsComponent.push(
                            <HbrMenu shellNav className={className}>
                                {submenuComponent}
                            </HbrMenu>
                        );

                } else if (isHeaderLabelFound === false) {
                    let path = isvManagePath ?
                        `/#/${submenu[submenuIndex]?.sref?.replaceAll(".", "/")?.replaceAll("admin/", "administration/").replaceAll("configurationGroups", "configurationGroups/sdwan")}` :
                        `${submenu[submenuIndex]?.path}`;
                    let menuLabel = submenu[submenuIndex].text;
                    let isvAnalyticsPath = false;
                    const submenuText = submenu[submenuIndex]?.text?.toLowerCase();
                    /**** Reports: Added for tick mark selection in reports menu ***/
                    let reportMenuSelection = false;
                    if (submenu[submenuIndex]?.feature === 'reports') {
                        var currMenuPath = submenu[submenuIndex]?.path.substring(path.indexOf('#') + 1);
                        var currUrlPath = window.location.hash.replace(/^#/, "");
                        if (currMenuPath === currUrlPath) {
                            reportMenuSelection = true;
                        }
                    }

                    if (isvManagePath) {
                        if (navItem.text === "Analytics" && submenuText === "overview") {
                            path = `${BASE_URL}/predictivenetworks`;
                            menuLabel = i18n.megaMenuPredictiveNetworks;
                            isvAnalyticsPath = true;
                        } else if (submenuText === "applications") {
                            path = `${BASE_URL}/applications`;
                            isvAnalyticsPath = true;
                        }
                    }
                    /**** Menu : Tick mark selection in Application 360 page ***/
                    let app360PathName = location.pathname.includes("/Dashboards");
                    //Use case 3 option 1: menu like Monitor menu that has no section, but just menu items
                    submenuComponent.push(
                        <HbrMenuItem
                            shellNav
                            value={menuLabel}
                            checked={menuLabel === 'Applications' && app360PathName || reportMenuSelection ? true : location.pathname.includes(menuLabel.toLowerCase().replaceAll(" ", ""))}
                            checkMark
                            onClick={(e) => handleMenuClick(path, isvManagePath, isvAnalyticsPath, e)}
                            key={Math.random()}
                        >
                            {menuLabel === "Logs" && FEATURE_CONFIG.LOGS.isBeta && !isvManagePath ?
                                <div className="menu-label">{menuLabel}<BetaTag msg={FEATURE_CONFIG.LOGS.betaMessage} /></div> :
                                <>{menuLabel}</>}
                        </HbrMenuItem>
                    )

                    if (isvManagePath && submenuText === "applications") {
                        const vaMenuArray = [i18n.megaMenuSitesLabel, i18n.megaMenuCircuits];
                        isvAnalyticsPath = true;

                        vaMenuArray.forEach(element => {
                            submenuComponent.push(
                                <HbrMenuItem
                                    shellNav
                                    value={element}
                                    checked={location.pathname.includes(element.toLowerCase())}
                                    checkMark
                                    onClick={() => handleMenuClick(`${BASE_URL}/${element.toLowerCase()}`, isvManagePath, isvAnalyticsPath)}
                                    key={Math.random()}
                                >
                                    {element}
                                </HbrMenuItem>
                            )
                        });
                    }
                }
            }
            //Use case 3 option 2: where menu has no sections
            if (isHeaderLabelFound === false) {
                MenuItemsComponent = (
                    <HbrMenu shellNav className="menu-item">
                        {submenuComponent}
                    </HbrMenu>)
            }
        }
    }

    const icon = getIconName(navItem.icon);
    const isSelected = navItem.text === "Analytics" && isPredictiveNetworksPage() ||
        navItem.text === "Monitor" && !isPredictiveNetworksPage() ||
        navItem.feature === "reports" && location.pathname?.includes(navItem.feature) ||
        navItem?.submenu?.find((item) => (item.path?.includes(location.pathname)));

    return (
        <HbrNavItem selected={isSelected} collapsed className="nav-item-section">
            {navItem.icon === "people" ?
                <Administrator
                    slot="prefix"
                /> :
                <HbrIcon
                    slot="prefix"
                    name={icon}
                />}
            {isSingleMenuItem === true ?
                <div slot="content" onClick={(event) => onClick(event)}>{navItem.text}</div>
                :
                <>
                    <div slot="content">
                        {navItem.text}
                    </div>
                    <div slot="drawerHeader">{navItem.text}</div>
                    <div slot="menu" className="sub-menu">
                        {MenuItemsComponent}
                    </div>
                </>
            }
        </HbrNavItem>
    )
}

Menu.propTypes = {
    navItem: PropTypes.object,
    onClick: PropTypes.func,
    isSingleMenuItem: PropTypes.bool
}

export default Menu;