import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";

//api const 
import { SETTINGS_URL } from "../../../../apis/apiConstants";

import i18n from "amdi18n-loader!../../../nls/i18n";

const [HbrModal, HbrButton] =
    reactWrapper(["hbr-modal", "hbr-button"]);

const AiFeatureModal = ({ refModal }) => {

    const onClickClose = () => {
        refModal.current.element.hide();
    }

    const onClickOpen = () => {
        //when the user click proceed we redirect to settings
        window.location.href = SETTINGS_URL;
    }

    return (
        <div>
            <HbrModal ref={refModal} label="Warning">
                <div>{i18n.modalText}</div>
                <div slot="footer" className="hbr-css__layout-row-md hbr-css__layout-justify-end">
                    <HbrButton variant="text" onClick={onClickClose}>
                        {i18n.modalCancel}
                    </HbrButton>
                    <HbrButton variant="fill" onClick={onClickOpen}>{i18n.modalProceed}</HbrButton>
                </div>
            </HbrModal>
        </div>
    )
}

AiFeatureModal.propTypes = {
    refModal: PropTypes.object
}
export default AiFeatureModal;