import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";
import {
  isCustom,
  updateCookie,
  setLocalStorageFlag,
  getStoredCurrentSite,
  getStoredTimePeriod,
  validateTimePeriodSelected,
  validateTimePeriodSelectedSidebarPayload,
  getConvergedCookie
} from "../../utils/common";
import { getDefaultTimeFilter } from "../../utils/query-utils";
import { setRumUser, updateRumSite } from "../../monitoring";
import {
  getTimePeriodELT,
  shortDisplayDate24HourWithSec,
  formatDate,
} from "../../utils/displayTime";
import { timeFilterTypesCustomV4, timeFilterTypes } from "../../utils/enums";
import { getCookie } from "../../serviceWorker"

const reduxContext = createContext();
export const storeKey = "vanalytics.app.globalFilter";
const currentOverlay = "currentOverlay";
const currentOverlayName = "currentOverlayName";
const roles = "roles";
export default reduxContext;
const currentSite = getStoredCurrentSite();
const timeFilterInit = getStoredTimePeriod();
const timePeriodArray = getTimePeriodELT(timeFilterInit);
const isUIConverged = getCookie("cl-analytics");
let v4PayloadObject = {
  time_frame: isUIConverged ? timeFilterTypes[getStoredTimePeriod()] : timeFilterTypesCustomV4[timeFilterInit],
  entry_ts: {
    start: shortDisplayDate24HourWithSec(
      formatDate(timePeriodArray.current_period[0]).utc()
    ),
    end: shortDisplayDate24HourWithSec(
      formatDate(timePeriodArray.current_period[1]).utc()
    ),
  },
}
if (currentSite && parseInt(currentSite) > 0) {
  v4PayloadObject["site_id"] = parseInt(currentSite);
}
const initialState = {
  overlays: [],
  application_table_data: [],
  app_family_sla: [],
  accounts: [],
  siteAPIQuery: {},
  sdwanSiteSummary: {},
  sdwanAPIQuery: {},
  sdwanSiteSummaryLoader: true,
  sdwanSiteSummaryError: null,
  talosData: {},
  talosAPIQuery: {},
  talosSitesLoader: true,
  talosSitesError: null,
  siteAPIData: {
    sitesTableData: [],
    overviewSiteDonut: [],
    overviewSiteStacked: [],
    sitesOverviewTab: [],
    app360Sites: [],
    overviewSiteLoader: true,
    filterViewLoaderSite: true,
  },
  overviewSiteSummary: {
    site_availability: {},
    avg_availability: {},
    usage: {},
  },
  refreshTo: null,
  deviceSiteOverview: {},
  selectedSiteQuery: {},
  deviceSummaryLoader: true,
  filterViewDevice: {},
  filterViewLoaderDevice: true,
  errorFilterViewDevice: null,
  app_data_with_family_sla: undefined,
  app_data_top_usage: undefined,
  app_data_with_family_sla_data_frame: undefined,
  ssoLogout: undefined,
  applicationAggregateData: {
    isloading: false,
    resObj: undefined,
  },
  appFamilySlaData: {
    isloading: false,
    resObj: undefined,
  },
  isSALoadedandNoData: false,
  isTimeFilterHidden: false,
  sites: [],
  breadCrumbsAry: [{ label: "Analytics", url: "/overview" }],
  selectedOverlay: null,
  selectedOverlayName: null,
  selectedSite:
    currentSite && parseInt(currentSite) > 0 ? parseInt(currentSite) : -1,
  selectedCustomTime: null,
  timePeriodSelected: timeFilterInit,
  currentTimeStamp: null,
  isSupportRole: false,
  appGroup: "all-apps-group",
  recommendedActionsView: "viewByGain",
  recommendedActionsSearch: "",
  predictiveTabSelected: "pprTab",
  timeFilter: {
    current_period: [
      timePeriodArray.current_period[0],
      timePeriodArray.current_period[1],
    ],
    previous_period: [
      timePeriodArray.previous_period[0],
      timePeriodArray.previous_period[1],
    ],
  },
  sideBarTimeFilter: {
    timePeriodSelected: timeFilterInit,
    currentTimeStamp: null,
    current_period: [
      timePeriodArray.current_period[0],
      timePeriodArray.current_period[1],
    ],
    previous_period: [
      timePeriodArray.previous_period[0],
      timePeriodArray.previous_period[1],
    ],
  },
  circuitsMasterTable: {
    query: {},
    queryString: "",
    loader: true,
    error: null,
    data: [],
    circuit_availability: {
      change: 0,
      current: 0,
      previous: 0,
    },
  },
  availableFeature: {},
  aggregateApplicationsData: {
    isloading: false,
    resObj: undefined,
  },
  selected_apps: [],
  selected_apps_legend: null,
  notifications: [],
  selected_prev_apps: null,
  overlay_reload_triggered: false,
  selected_apps_default: null,
  status: "",
  trend_analysis_table_filter: undefined,
  sidebar_custom_table_data: undefined,
  onAnomalyRefresh: null,
  onKpiRefresh: null,
  pathanalyticsTabView: false,
  menuState: { isError: false, data: null },
  globalV4Payload: v4PayloadObject,
  globalV4PayloadSidebar: JSON.parse(JSON.stringify(v4PayloadObject)),
  bandwidthLineChart: undefined,
  circuitsObjList: [],
  bandWidthWidget: { isloading: true, resObj: undefined },
  trendHeatWidget: { isloading: true, resObj: undefined },
  selectedTrendCircuits: [],
  trendCircuitsApiData: {},
  bandwidthProps: { circuit: "", associatedOverlay: "" },
  appFlowTimeframe: ''
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_KPI_REFRESH:
      return {
        ...state,
        onKpiRefresh: payload,
      };
    case ACTIONS.SET_PATH_ANALYTICS_TAB:
      return {
        ...state,
        pathanalyticsTabView: payload
      };
    case ACTIONS.SET_MENU_STATE:
      return {
        ...state,
        menuState: payload,
      };
    case ACTIONS.SET_ANOMALY_REFRESH:
      return {
        ...state,
        onAnomalyRefresh: payload,
      };
    case ACTIONS.SET_SSO_LOGOUT:
      return {
        ...state,
        ssoLogout: payload,
      };

    case ACTIONS.SET_SDWAN_SITE_SUMMARY:
      return {
        ...state,
        sdwanSiteSummary: payload.sdwanSiteSummary,
        sdwanAPIQuery: payload.sdwanAPIQuery,
        sdwanSiteSummaryLoader: false,
      };

    case ACTIONS.SET_SDWAN_SITE_SUMMARY_ERROR: {
      return {
        ...state,
        sdwanSiteSummary: {},
        sdwanSiteSummaryError: payload.errorObject,
        sdwanSiteSummaryLoader: false,
      };
    }
    case ACTIONS.SET_SDWAN_SITE_SUMMARY_LOADER: {
      return {
        ...state,
        sdwanSiteSummary: {},
        sdwanSiteSummaryLoader: true,
        sdwanSiteSummaryError: null,
      };
    }

    case ACTIONS.SET_TALOS_SITES:
      return {
        ...state,
        talosData: payload.talosData,
        talosAPIQuery: payload.talosAPIQuery,
        talosSitesLoader: false
      };
    case ACTIONS.SET_TALOS_ERROR: {
      return {
        ...state,
        talosData: undefined,
        talosSitesError: payload.errorObject,
        talosSitesLoader: false
      };
    }
    case ACTIONS.SET_TALOS_LOADER: {
      return {
        ...state,
        talosData: undefined,
        talosSitesError: null,
        talosSitesLoader: true
      };
    }

    case ACTIONS.SET_SITES_TABLE_AVAILABILITY:
      return {
        ...state,
        siteAPIData: {
          sitesTableData: payload.sitesTableData,
          overviewSiteDonut: payload.overviewSiteDonut,
          overviewSiteStacked: payload.overviewSiteStacked,
          sitesOverviewTab: payload.sitesOverviewTab,
          app360Sites: payload.app360Sites,
          overviewSiteLoader: false,
          filterViewLoaderSite: false,
        },
        overviewSiteSummary: {
          ...state.overviewSiteSummary,
          site_availability: {
            ...payload.overviewSiteSummary.site_availability,
          },
        },
        reportOverviewSummary: {
          site_availability: payload.overviewSiteSummary.site_availability,
        },
        siteAPIQuery: payload.siteAPIQuery,
        errorFilterViewDevice: null,
      };

    case ACTIONS.SET_OVERVIEW_SITE_LOADER:
      return {
        ...state,
        siteAPIData: {
          sitesTableData: [],
          overviewSiteDonut: [],
          overviewSiteStacked: [],
          overviewSiteSummary: [],
          sitesOverviewTab: [],
          overviewSiteLoader: true,
          filterViewLoaderSite: true,
        },
      };

    case ACTIONS.SET_OVERVIEW_DEVICE_LOADER:
      return {
        ...state,
        deviceSummaryLoader: payload,
        filterViewLoaderDevice: payload,
      };

    case ACTIONS.SET_OVERVIEW_ERROR: {
      return {
        ...state,
        siteAPIData: {
          sitesTableData: [],
          overviewSiteDonut: [],
          overviewSiteStacked: [],
          overviewSiteSummary: [],
          sitesOverviewTab: [],
          overviewSiteLoader: false,
          filterViewLoaderSite: false,
          overviewSiteError: payload.errorObject,
          errorFilterViewSite: payload.errorObject, // overview sites summary error
        },
      };
    }

    case ACTIONS.SET_DEVICE_OVERVIEW_ERROR: {
      return {
        ...state,
        deviceSiteOverview: {},
        deviceSummaryLoader: false,
        filterViewDevice: { devices: payload.errorObject },
        filterViewLoaderDevice: false,
        errorFilterViewDevice: payload.errorObject,
      };
    }
    case ACTIONS.SET_DEVICE_SITE:
      return {
        ...state,
        overviewSiteSummary: {
          ...state.overviewSiteSummary,
          avg_availability: {
            ...payload.avg_availability,
          },
          usage: {
            ...payload.usageSummary,
          },
        },
        deviceSiteOverview: payload.deviceData,
        filterViewDevice: { devices: payload.deviceSitesummary },
        selectedSiteQuery: payload.selectedSiteQuery,
        deviceSummaryLoader: false,
        filterViewLoaderDevice: false,
        errorFilterViewDevice: null,
      };
    case ACTIONS.SET_OVERLAYS:
      return {
        ...state,
        overlays: payload,
      };
    case ACTIONS.SET_SELECTED_OVERLAY: {
      localStorage.setItem(currentOverlay, payload.overlayId);
      localStorage.setItem(currentOverlayName, payload.overlayName);
      setRumUser(payload.overlayId);
      updateRumSite(-1);
      return {
        ...state,
        selectedOverlay: payload.overlayId,
        selectedOverlayName: payload.overlayName,
        refreshTo: null,
        onAnomalyRefresh: null,
      };
    }
    case ACTIONS.SET_SIDE_BAR_TIME_FILTER: {
      const updateSideBarFilter = payload.time_period === null ?
        {
          current_period: state.sideBarTimeFilter.current_period,
          previous_period: state.sideBarTimeFilter.previous_period
        }
        : state.selectedSite > 0
          ? {
            ...payload.time_period,
            ...{
              filter: {
                site_id: state.selectedSite,
              },
            },
          }
          : getDefaultTimeFilter(payload);
      if (isCustom(payload.timePeriodSelected) === false) {
        state.globalV4PayloadSidebar["time_frame"] =
          validateTimePeriodSelectedSidebarPayload(payload);
        state.globalV4PayloadSidebar["entry_ts"]["start"] =
          shortDisplayDate24HourWithSec(
            formatDate(payload.time_period.current_period[0]).utc()
          );
        state.globalV4PayloadSidebar["entry_ts"]["end"] =
          shortDisplayDate24HourWithSec(
            formatDate(payload.time_period.current_period[1]).utc()
          );
      }
      return {
        ...state,
        sideBarTimeFilter: {
          ...{ timePeriodSelected: payload.timePeriodSelected },
          ...updateSideBarFilter,
        },
      };
    }

    case ACTIONS.SET_CTRL_TIME_FILTER: {
      const updatedFilter =
        state.selectedSite > 0
          ? {
            ...payload.time_period,
            ...{
              filter: {
                site_id: state.selectedSite,
              },
            },
          }
          : getDefaultTimeFilter(payload);

      if (isCustom(payload.timePeriodSelected) === false) {
        state.globalV4Payload["time_frame"] = validateTimePeriodSelected(
          payload.timePeriodSelected
        );
        state.globalV4Payload["entry_ts"]["start"] =
          shortDisplayDate24HourWithSec(
            formatDate(payload.time_period.current_period[0]).utc()
          );
        state.globalV4Payload["entry_ts"]["end"] =
          shortDisplayDate24HourWithSec(
            formatDate(payload.time_period.current_period[1]).utc()
          );
        state.globalV4PayloadSidebar["time_frame"] = validateTimePeriodSelected(
          payload.timePeriodSelected
        );
        state.globalV4PayloadSidebar["entry_ts"]["start"] =
          shortDisplayDate24HourWithSec(
            formatDate(payload.time_period.current_period[0]).utc()
          );
        state.globalV4PayloadSidebar["entry_ts"]["end"] =
          shortDisplayDate24HourWithSec(
            formatDate(payload.time_period.current_period[1]).utc()
          );
      }

      return {
        ...state,
        selectedCustomTime: !isCustom(payload.timePeriodSelected)
          ? null
          : state.selectedCustomTime,
        timeFilter: !isCustom(payload.timePeriodSelected)
          ? { ...updatedFilter }
          : state.timeFilter,
        timePeriodSelected: payload.timePeriodSelected,
        sideBarTimeFilter: {
          current_period: payload.time_period.current_period,
          previous_period: payload.time_period.previous_period,
        },
        siteAPIQuery: {
          timePeriodSelected: payload.timePeriodSelected,
        },
        selectedSiteQuery: {
          timePeriodSelected: payload.timePeriodSelected,
        },
      };
    }
    case ACTIONS.SET_REFRESH:
      return {
        ...state,
        currentTimeStamp: payload,
        sideBarTimeFilter: {
          ...state.sideBarTimeFilter,
          currentTimeStamp: payload,
        },
        refreshTo: Math.random(),
      };
    case ACTIONS.SET_SITES:
      return {
        ...state,
        sites: payload,
      };
    case ACTIONS.SET_SELECTED_SITE: {
      const convergedSite = getConvergedCookie("currentSite");
      const payloadSite = parseInt(payload);
      let siteId = isUIConverged && convergedSite > 0 && payloadSite !== -1 ? convergedSite : payloadSite;

      if (payloadSite !== convergedSite) {
        siteId = payloadSite;
      }

      if (isUIConverged) {
        if (siteId > 0)
          updateCookie("currentSite", siteId);
        else {
          updateCookie("currentSite", null);
        }
      }


      setLocalStorageFlag("currentSite", siteId);
      updateRumSite(siteId);

      if (
        payload == undefined ||
        (state.timeFilter.filter && siteId === state.timeFilter.filter.site_id)
      )
        return state;

      const updatedFilterWithSite =
        siteId > 0
          ? {
            ...state.timeFilter,
            ...{
              filter: {
                site_id: siteId,
              },
            },
          }
          : getDefaultTimeFilter(state.timeFilter);

      if (parseInt(payload) > 0) {
        state.globalV4Payload["site_id"] = parseInt(payload);
        state.globalV4PayloadSidebar["site_id"] = parseInt(payload);
      } else {
        if ("site_id" in state.globalV4Payload) {
          delete state.globalV4Payload.site_id;
        }
        if ("site_id" in state.globalV4PayloadSidebar) {
          delete state.globalV4PayloadSidebar.site_id;
        }
      }

      return {
        ...state,
        selectedSite: siteId,
        timeFilter: updatedFilterWithSite,
      };
    }

    case ACTIONS.SET_CUSTOM_TIME: {
      const siteFilter =
        state.selectedSite > 0
          ? {
            filter: {
              site_id: state.selectedSite,
            },
          }
          : {};
      const _timePeriod = {
        current_period: payload.current_period,
        previous_period: payload.previous_period,
        ...siteFilter,
      };
      state.globalV4Payload["time_frame"] = "custom";
      state.globalV4Payload["entry_ts"]["start"] =
        shortDisplayDate24HourWithSec(
          formatDate(payload.current_period[0]).utc()
        );
      state.globalV4Payload["entry_ts"]["end"] =
        shortDisplayDate24HourWithSec(
          formatDate(payload.current_period[1]).utc()
        );
      state.globalV4PayloadSidebar["time_frame"] = "custom";
      state.globalV4PayloadSidebar["entry_ts"]["start"] =
        shortDisplayDate24HourWithSec(
          formatDate(payload.current_period[0]).utc()
        );
      state.globalV4PayloadSidebar["entry_ts"]["end"] =
        shortDisplayDate24HourWithSec(
          formatDate(payload.current_period[1]).utc()
        );
      return {
        ...state,
        selectedCustomTime: payload,
        timeFilter: { ..._timePeriod },
        sideBarTimeFilter: { ..._timePeriod },
      };
    }

    case ACTIONS.SET_SIDE_BAR_CUSTOM_TIME: {
      state.globalV4PayloadSidebar["time_frame"] = "custom";
      state.globalV4PayloadSidebar["entry_ts"]["start"] =
        shortDisplayDate24HourWithSec(
          formatDate(payload.current_period[0]).utc()
        );
      state.globalV4PayloadSidebar["entry_ts"]["end"] =
        shortDisplayDate24HourWithSec(
          formatDate(payload.current_period[1]).utc()
        );
      return {
        ...state,
        sideBarTimeFilter: { ...payload },
      };
    }

    case ACTIONS.SET_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
        isSALoadedandNoData: true,
      };

    case ACTIONS.SET_SELECTED_OVERLAY_ROLES: {
      localStorage.setItem(roles, JSON.stringify(payload));
      return {
        ...state,
        roles: payload,
      };
    }

    case ACTIONS.SET_ALTO_PROPS:
      return {
        ...state,
        ...payload,
      };

    case ACTIONS.SET_SUPPORT_ROLE:
      return {
        ...state,
        isSupportRole: payload,
      };

    case ACTIONS.SET_BREADCRUMBS:
      return {
        ...state,
        breadCrumbsAry: payload,
      };
    case ACTIONS.SET_APPLICATIONDATA_API_STATUS:
      return {
        ...state,
        application_table_data: payload.data,
        applicationAggregateData: {
          isloading: false,
          resObj: {
            errorCode:
              payload.errorObject && payload.errorObject.errorCode
                ? payload.errorObject.errorCode
                : 200,
            message:
              payload.errorObject && payload.errorObject.message
                ? payload.errorObject.message
                : "successful",
          },
        },
      };
    case ACTIONS.SET_APP_FAMILY_SLA:
      return {
        ...state,
        app_family_sla: payload.data,
        appFamilySlaData: {
          isloading: false,
          resObj: {
            errorCode:
              payload.errorObject && payload.errorObject.errorCode
                ? payload.errorObject.errorCode
                : 200,
            message:
              payload.errorObject && payload.errorObject.message
                ? payload.errorObject.message
                : "successful",
          },
        },
      };

    case ACTIONS.SET_APP_DATA_WITH_FAMILY_SLA:
      return {
        ...state,
        app_data_with_family_sla: payload,
        app_data_top_usage: payload ? payload.top_apps_usage : "",
        appFamilyWithFamilySlaData: {
          isloading: false,
          resObj: {
            errorCode: 200,
            message: "successful",
          },
        },
      };

    case ACTIONS.setCircuitsLoader:
      return {
        ...state,
        circuitsMasterTable: { ...initialState.circuitsMasterTable },
      };

    case ACTIONS.setCircuitsData:
      return {
        ...state,
        circuitsMasterTable: {
          ...state.circuitsMasterTable,
          ...payload,
        },
      };

    case ACTIONS.SET_AVAILABLE_FEATURE_FLAGS:
      return {
        ...state,
        availableFeature: payload,
      };
    case ACTIONS.SET_APP_DATA_SELECTED:
      return {
        ...state,
        selected_apps: payload,
      };
    case ACTIONS.SET_APP_DATA_SELECTED_LEGEND:
      return {
        ...state,
        selected_apps_legend: payload,
      };
    case ACTIONS.SET_POST_LOGIN_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      };
    case ACTIONS.SET_PREV_APP_DATA_SELECTED:
      return {
        ...state,
        selected_prev_apps: payload,
      };
    case ACTIONS.SET_OVR_RELOAD:
      return {
        ...state,
        overlay_reload_triggered: payload,
      };
    case ACTIONS.SET_APP_DATA_SELECTED_DEFAULT:
      return {
        ...state,
        selected_apps_default: payload,
      };
    case ACTIONS.SET_TREND_ANALY_FILTER:
      return {
        ...state,
        trend_analysis_table_filter: payload,
      };
    case ACTIONS.SET_SIDEBAR_TABLE_DATA:
      return {
        ...state,
        sidebar_custom_table_data: payload,
      };
    case ACTIONS.SET_BANDWIDTH_CHART_DATA:
      return state.selectedCircuits
        .every(item => payload.circuitNames.includes(item))
        ? {
          ...state,
          bandwidthLineChart: payload.data
        }
        : state;
    case ACTIONS.SET_CIRCUITS_OBJECTLIST:
      return {
        ...state,
        circuitsObjList: payload
      };
    case ACTIONS.GET_SELECTED_CIRCUITS_LIST:
      return {
        ...state,
        selectedCircuits: payload
      };
    case ACTIONS.BW_API_STATUS:
      return {
        ...state,
        bandWidthWidget: {
          isloading: payload.isloading,
          resObj: payload.resObj
        }
      };
    case ACTIONS.HEATMAP_API_STATUS:
      return {
        ...state,
        trendHeatWidget: {
          isloading: payload.isloading,
          resObj: payload.resObj
        }
      };
    case ACTIONS.GET_SELECTED_TREND_CIRCUITS_LIST:
      return {
        ...state,
        selectedTrendCircuits: payload
      };
    case ACTIONS.RESET_HEATMAP_AVAIL_CHANGE:
      return {
        ...state,
        trendCircuitsApiData: {}
      };
    case ACTIONS.SET_TREND_CIRCUITS_AVAIL:
      return {
        ...state,
        trendCircuitsApiData: payload,
        trendHeatWidget: {
          isloading: false,
          resObj: { errorCode: 200, message: "successful" }
        }
      };
    case ACTIONS.GET_BANDWIDTH_FORECAST_CIRCUIT:
      return {
        ...state,
        bandwidthProps: payload
      };
    case ACTIONS.TIMEFRAME_APP_FLOW:
      return {
        ...state,
        appFlowTimeframe: payload
      };

    case ACTIONS.SET_TOGGLE:
      return {
        ...state,
        toggleView: payload
      }
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SET_OVERLAYS,
    ACTIONS.SET_PATH_ANALYTICS_TAB,
    ACTIONS.SET_SELECTED_OVERLAY,
    ACTIONS.SET_CTRL_TIME_FILTER,
    ACTIONS.SET_SITES,
    ACTIONS.SET_SELECTED_SITE,
    ACTIONS.SET_CUSTOM_TIME,
    ACTIONS.SET_SIDE_BAR_TIME_FILTER,
    ACTIONS.SET_SIDE_BAR_CUSTOM_TIME,
    ACTIONS.SET_ACCOUNTS,
    ACTIONS.SET_SELECTED_OVERLAY_ROLES,
    ACTIONS.SET_REFRESH,
    ACTIONS.SET_ALTO_PROPS,
    ACTIONS.SET_SUPPORT_ROLE,
    ACTIONS.SET_BREADCRUMBS,
    ACTIONS.setCircuitsLoader,
    ACTIONS.setCircuitsData,
    ACTIONS.SET_SITES_TABLE_AVAILABILITY,
    ACTIONS.SET_SDWAN_SITE_SUMMARY,
    ACTIONS.SET_SDWAN_SITE_SUMMARY_ERROR,
    ACTIONS.SET_SDWAN_SITE_SUMMARY_LOADER,
    ACTIONS.SET_TALOS_SITES,
    ACTIONS.SET_TALOS_ERROR,
    ACTIONS.SET_TALOS_LOADER,
    ACTIONS.SET_OVERVIEW_SITE_LOADER,
    ACTIONS.SET_OVERVIEW_DEVICE_LOADER,
    ACTIONS.SET_OVERVIEW_ERROR,
    ACTIONS.SET_DEVICE_SITE,
    ACTIONS.SET_DEVICE_OVERVIEW_ERROR,
    ACTIONS.SET_AVAILABLE_FEATURE_FLAGS,
    ACTIONS.SET_BREADCRUMBS,
    ACTIONS.SET_APPLICATIONDATA_API_STATUS,
    ACTIONS.SET_APP_FAMILY_SLA,
    ACTIONS.SET_APP_DATA_WITH_FAMILY_SLA,
    ACTIONS.SET_APP_DATA_SELECTED,
    ACTIONS.SET_APP_DATA_SELECTED_LEGEND,
    ACTIONS.SET_POST_LOGIN_NOTIFICATIONS,
    ACTIONS.SET_PREV_APP_DATA_SELECTED,
    ACTIONS.SET_OVR_RELOAD,
    ACTIONS.SET_APP_DATA_SELECTED_DEFAULT,
    ACTIONS.SET_TREND_ANALY_FILTER,
    ACTIONS.SET_SIDEBAR_TABLE_DATA,
    ACTIONS.SET_ANOMALY_REFRESH,
    ACTIONS.SET_KPI_REFRESH,
    ACTIONS.SET_MENU_STATE,
    ACTIONS.SET_BANDWIDTH_CHART_DATA,
    ACTIONS.SET_CIRCUITS_OBJECTLIST,
    ACTIONS.GET_SELECTED_CIRCUITS_LIST,
    ACTIONS.BW_API_STATUS,
    ACTIONS.HEATMAP_API_STATUS,
    ACTIONS.GET_SELECTED_TREND_CIRCUITS_LIST,
    ACTIONS.RESET_HEATMAP_AVAIL_CHANGE,
    ACTIONS.SET_TREND_CIRCUITS_AVAIL,
    ACTIONS.GET_BANDWIDTH_FORECAST_CIRCUIT,
    ACTIONS.TIMEFRAME_APP_FLOW,
    ACTIONS.SET_TOGGLE
  ],
});

