import React, { useState, useEffect, useMemo, useCallback } from "react";
import { PropTypes } from "prop-types";
import { FixedSizeList as List } from "react-window";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { DnxIcon } from "../../loaders/DNXLoader";
import { useMount } from "../../utils/genericCommon";
import { getCustomListApi } from "../../utils/common";
import i18n from "amdi18n-loader!../nls/i18n";
import css from "../genericMagneticStyle.less";

const [
  HbrDropdown,
  HbrButton,
  HbrMenu,
  HbrInput,
  HbrMenuItem,
  HbrIcon,
  HbrTooltip
] = reactWrapper([
  "hbr-dropdown",
  "hbr-button",
  "hbr-menu",
  "hbr-input",
  "hbr-menu-item",
  "hbr-icon",
  "hbr-tooltip"
]);
const defaultSite = { value: -1, label: i18n.appHocAllSites }

const SitesDropdown = props => {
  const [siteList, setSiteList] = useState([]);
  const [searchedSiteList, setSearchedSiteList] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const mount = useMount();
  const { selectedSite } = props.globalFilter;

  const siteName = useMemo(() => {
    if (selectedSite === -1) return i18n.appHocAllSites;
    else {
      const site = siteList.find(item => item.value === selectedSite);
      if (site) return site.label;
      else return "";
    }
  }, [selectedSite, siteList]);

  useEffect(() => {
    const sites = props.globalFilter.sites
      .map(item => ({ value: item.site_id, label: item.site_name }));
    setSiteList(sites);
    const filteredSites = sites.slice(0);
    if (filteredSites.length > 0) filteredSites.unshift(defaultSite);
    setSearchedSiteList(filteredSites);
  }, [props.globalFilter.sites]);

  useEffect(() => {
    if (!mount.initMount) {
      /* This will call custom list api whenever we change site from site filter */
      getCustomListApi(props);
    } else mount.initMount = false;
  }, [selectedSite]);

  const onSearch = e => {
    let searchText = e.target.value;
    let filteredSites = [];
    if (searchText) {
      searchText = searchText.toLowerCase();
      filteredSites = siteList.filter(
        item => item.label.toLowerCase().includes(searchText)
      );
    } else filteredSites = siteList.slice(0);
    if (filteredSites.length > 0) filteredSites.unshift(defaultSite);
    setSearchedSiteList(filteredSites);
  };

  const onButtonClick = () => setExpanded(true);

  const onMenuItemClick = useCallback(event => {
    // clear the redux store attribute on site change
    const value = event.currentTarget.value;
    if (
      event.target.name === "caret-down"
      || event.target.name === "caret-right"
    ) {
      event.preventDefault();
      event.stopPropagation();
      if (event.target.name === "caret-down") setExpanded(false);
      else setExpanded(true);
    } else if (selectedSite.toString() !== value.toString()) {
      props.setAppsWithFamilySLA(undefined);
      props.setSelectedSite(value);
    }
  }, [selectedSite]);

  return (
    <div
      className={css["sites-dropdown-component"]}
      data-cy="sites-dropdown-component"
    >
      {props.globalFilter.sites.length > 0 && (
        <HbrDropdown onClick={onButtonClick}>
          <HbrButton
            slot="trigger"
            variant="outline"
            sentiment="neutral"
            caret
          >
            {selectedSite === -1 ? (
              <HbrIcon
                slot="prefix"
                sentiment="neutral"
                name="nav-domains"
              />
            ) : (
              <DnxIcon
                slot="prefix"
                name="building"
                resolution="16"
              />
            )}
            <span className="label hbr-css__text-primary-sm-bold">
              {siteName}
            </span>
          </HbrButton>
          <HbrMenu>
            <div className="site-menu-container">
              <HbrInput
                className="sites-container"
                size="medium"
                placeholder={i18n.megaMenuSearchSite}
                clearable
                onHbr-input={onSearch}
              >
                <div slot="prefix">
                  <HbrIcon sentiment="neutral" name="magnifying-glass" />
                </div>
              </HbrInput>
              {searchedSiteList.length > 0 ? (
                <List
                  className="List"
                  height={190}
                  itemCount={expanded === true ? searchedSiteList.length : 1}
                  itemSize={40}
                  width={250}
                >
                  {({ index, style }) => {
                    const { label, value } = searchedSiteList[index];
                    return (
                      <div
                        className={
                          `ListItem ${value === -1 ? "" : "menu-item-container"}`
                        }
                        style={style}
                      >
                        <HbrMenuItem
                          key={value}
                          value={value}
                          onClick={onMenuItemClick}
                        >
                          {value === -1 ? (
                            <HbrIcon
                              slot="prefix"
                              sentiment="neutral"
                              name="nav-domains"
                            />
                          ) : (
                            <DnxIcon
                              slot="prefix"
                              name="building"
                              resolution="16"
                            />
                          )}
                          {value === -1 ? (
                            <div
                              className="hbr-css__text-secondary-sm-semibold"
                            >
                              {i18n.megaMenuSearchGlobal}
                            </div>
                          ) : (
                            <>
                              <div
                                id={`site_id_${value}`}
                                className="hbr-css__text-secondary-sm-semibold site-text"
                              >
                                {label}
                              </div>
                              <hbr-portal>
                                <HbrTooltip
                                  anchor={`#site_id_${value}`}
                                  placement="right"
                                  hoist
                                >
                                  <div slot="content">{label}</div>
                                </HbrTooltip>
                              </hbr-portal>
                            </>
                          )}
                          {value === -1 && (
                            <HbrIcon
                              slot="suffix"
                              sentiment="neutral"
                              name={expanded === true
                                ? "caret-down"
                                : "caret-right"}
                            />
                          )}
                        </HbrMenuItem>
                      </div>
                    );
                  }}
                </List>
              ) : (
                <div className="no-site-data hbr-css__text-secondary-sm">
                  {i18n.emptyData}
                </div>
              )}
            </div>
          </HbrMenu>
        </HbrDropdown>
      )}
    </div>
  );
};
SitesDropdown.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  setSelectedSite: PropTypes.func,
  setAppsWithFamilySLA: PropTypes.func.isRequired
};
export default SitesDropdown;
