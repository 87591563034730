import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
/* eslint-disable react/prop-types */

import {
  setCtrlTimeFilter,
  setSideBarTimeFilter,
  setSelectedOverlay,
  setOverlays,
  setSites,
  setSelectedSite,
  setCustomTime,
  setSideBarCustomTime,
  setAccounts,
  setSelectedOverlayRoles,
  setRefreshScreen,
  setAltoProps,
  setSupportRole,
  setSsoLogoutCookie,
  setBreadCrumbs,
  setCircuitsLoader,
  setCircuitsData,
  setSitesTableAvailability,
  setOverviewError,
  setOverviewSiteLoader,
  setOverviewDeviceLoader,
  saveDeviceSiteData,
  setDeviceOverviewError,
  setAvailableFeatureFlags,
  setAggregateAppData,
  setAppFamilySLA,
  setAppsWithFamilySLA,
  setSelectedAppList,
  setSelectedAppListLegend,
  setPostLoginNotifications,
  setPrevSelectedAppList,
  setOverlayReload,
  setSelectedAppListDefault,
  setTrendAnalysisFilter,
  setSidebarTableData,
  setAnomalyRefresh,
  setSdwanSiteSummary,
  setSdwanSiteSummaryError,
  setSdwanSiteSummaryLoader,
  setTalosSites,
  setTalosError,
  setTalosLoader,
  setKpiRefresh,
  setPathAnalyticsTab,
  setMenuState,
  setBandwidthCircuitsData,
  setSelectedCircuits,
  setSelectedCircuitsObject,
  setbandWidthWidget,
  heatMapApiStatus,
  selectedTrendCircuits,
  resetHeatMapAvail,
  setTrendAvail,
  setBandwidthForecastCircuit,
  setTimeframeFlowsWidget,
  setPageToggle
} from "./actions";
import reduxContext from "./reducer";

const mapDispatchToProps = {
  setRefreshScreen,
  setCtrlTimeFilter,
  setSideBarTimeFilter,
  setOverlays,
  setSelectedOverlay,
  setSites,
  setSelectedSite,
  setCustomTime,
  setSideBarCustomTime,
  setAccounts,
  setSelectedOverlayRoles,
  setAltoProps,
  setSupportRole,
  setSsoLogoutCookie,
  setBreadCrumbs,
  setCircuitsLoader,
  setCircuitsData,
  setSitesTableAvailability,
  setOverviewError,
  setOverviewSiteLoader,
  setOverviewDeviceLoader,
  saveDeviceSiteData,
  setDeviceOverviewError,
  setAvailableFeatureFlags,
  setAggregateAppData,
  setAppFamilySLA,
  setAppsWithFamilySLA,
  setSelectedAppList,
  setSelectedAppListLegend,
  setPostLoginNotifications,
  setPrevSelectedAppList,
  setOverlayReload,
  setSelectedAppListDefault,
  setTrendAnalysisFilter,
  setSidebarTableData,
  setAnomalyRefresh,
  setSdwanSiteSummary,
  setSdwanSiteSummaryError,
  setSdwanSiteSummaryLoader,
  setTalosError,
  setTalosLoader,
  setTalosSites,
  setKpiRefresh,
  setPathAnalyticsTab,
  setMenuState,
  setBandwidthCircuitsData,
  setSelectedCircuits,
  setSelectedCircuitsObject,
  setbandWidthWidget,
  heatMapApiStatus,
  selectedTrendCircuits,
  resetHeatMapAvail,
  setTrendAvail,
  setBandwidthForecastCircuit,
  setTimeframeFlowsWidget,
  setPageToggle
};

const mapStateToProps = state => {
  return {
    globalFilter: state.vanalytics.app.globalFilter
  };
};

const AppHoc = WrappedComponent => {
  class PageComponent extends React.Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          setSsoLogoutCookie={this.props.setSsoLogoutCookie}
          setRefreshScreen={this.props.setRefreshScreen}
          setCtrlTimeFilter={this.props.setCtrlTimeFilter}
          setSideBarTimeFilter={this.props.setSideBarTimeFilter}
          setOverlays={this.props.setOverlays}
          setSelectedOverlay={this.props.setSelectedOverlay}
          setSites={this.props.setSites}
          setSelectedSite={this.props.setSelectedSite}
          setCustomTime={this.props.setCustomTime}
          setSideBarCustomTime={this.props.setSideBarCustomTime}
          setAccounts={this.props.setAccounts}
          setSelectedOverlayRoles={this.props.setSelectedOverlayRoles}
          setAltoProps={this.props.setAltoProps}
          setSupportRole={this.props.setSupportRole}
          setBreadCrumbs={this.props.setBreadCrumbs}
          setCircuitsLoader={this.props.setCircuitsLoader}
          setCircuitsData={this.props.setCircuitsData}
          setSitesTableAvailability={this.props.setSitesTableAvailability}
          setOverviewError={this.props.setOverviewError}
          setOverviewSiteLoader={this.props.setOverviewSiteLoader}
          setOverviewDeviceLoader={this.props.setOverviewDeviceLoader}
          saveDeviceSiteData={this.props.saveDeviceSiteData}
          setDeviceOverviewError={this.props.setDeviceOverviewError}
          setAvailableFeatureFlags={this.props.setAvailableFeatureFlags}
          setAggregateAppData={this.props.setAggregateAppData}
          setAppFamilySLA={this.props.setAppFamilySLA}
          setAppsWithFamilySLA={this.props.setAppsWithFamilySLA}
          setSelectedAppList={this.props.setSelectedAppList}
          setSelectedAppListLegend={this.props.setSelectedAppListLegend}
          setPostLoginNotifications={this.props.setPostLoginNotifications}
          setPrevSelectedAppList={this.props.setPrevSelectedAppList}
          setOverlayReload={this.props.setOverlayReload}
          setTrendAnalysisFilter={this.props.setTrendAnalysisFilter}
          setSidebarTableData={this.props.setSidebarTableData}
          setAnomalyRefresh={this.props.setAnomalyRefresh}
          setSdwanSiteSummary={this.props.setSdwanSiteSummary}
          setSdwanSiteSummaryError={this.props.setSdwanSiteSummaryError}
          setSdwanSiteSummaryLoader={this.props.setSdwanSiteSummaryLoader}
          setTalosSites={this.props.setTalosSites}
          setTalosError={this.props.setTalosError}
          setTalosLoader={this.props.setTalosLoader}
          setKpiRefresh={this.props.setKpiRefresh}
          setPathAnalyticsTab={this.props.setPathAnalyticsTab}
          setMenuState={this.props.setMenuState}
          setBandwidthCircuitsData={this.props.setBandwidthCircuitsData}
          setSelectedCircuits={this.props.setSelectedCircuits}
          setSelectedCircuitsObject={this.props.setSelectedCircuitsObject}
          setbandWidthWidget={this.props.setbandWidthWidget}
          heatMapApiStatus={this.props.heatMapApiStatus}
          selectedTrendCircuits={this.props.selectedTrendCircuits}
          resetHeatMapAvail={this.props.resetHeatMapAvail}
          setTrendAvail={this.props.setTrendAvail}
          setBandwidthForecastCircuit={this.props.setBandwidthForecastCircuit}
          setTimeframeFlowsWidget={this.props.setTimeframeFlowsWidget}
          setPageToggle={this.props.setPageToggle}
        />
      );
    }
  }

  return reduxContext.withProvider(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(PageComponent))
  );
};

export default AppHoc;
