import React from "react";
import { PropTypes } from "prop-types";
import { Responsive, WidthProvider } from "react-grid-layout";
import { generateLayout } from "../../utils/genericCommon";
import css from "../genericMagneticStyle.less";

const ResponsiveGridLayout = WidthProvider(Responsive);

const GridContainer = ({ filterDashlets, dashlets }) => {
  const marginPix = 16;
  const containerRowHeightPix = 10;
  const gridProps = {
    rowHeight: containerRowHeightPix,
    isDraggable: false,
    isResizable: false,
    margin: [0, 0], // BUGGY
    zIndex: 8888,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
    useCSSTransforms: false
  };

  const widgetGridProps = {
    rowHeight: 0,
    margin: [marginPix, marginPix], //height united is somehow caluclated from 0 + 16, line 84
    isDraggable: false,
    isResizable: false,
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 12, sm: 12, xs: 4, xxs: 4 }, //    cols: {lg:12, md: 12, sm: 6, xs: 4, xxs: 2},
    containerPadding: [24, 24],
    useCSSTransforms: false
  };

  //When in responsive mode, you should supply at least one breakpoint via the layouts property.
  //When using layouts, it is best to supply as many breakpoints as possible, especially the largest one. If the largest is provided, RGL will attempt to interpolate the rest.
  const layouts = { lg: generateLayout() };

  return (
    <div className={"converged-grid-container"} >
      <div
        key="filters"
        className={css["filter-widget-area"]}
      // bug: https://github.com/STRML/react-grid-layout/issues/493
      >
        <ResponsiveGridLayout
          containerPadding={[24, 24]}
          {...gridProps}
          layouts={layouts}
        >
          {filterDashlets.map(dashlet => (
            <div
              key={dashlet.name}
              data-grid={dashlet.layout}
              style={dashlet.style}
            >
              <dashlet.component />
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
      <div key="widgets" className={css["route-frame-widget-area"]}>
        <ResponsiveGridLayout {...widgetGridProps} layouts={layouts}>
          {dashlets.map(dashlet => (
            <div
              key={dashlet.name}
              data-grid={{ ...dashlet.layout, h: dashlet.layout.h + 1 }} // for margin bug
              style={dashlet.style}
            >
              <dashlet.component />
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </div>
  );
};

GridContainer.propTypes = {
  filterDashlets: PropTypes.array,
  dashlets: PropTypes.array
};

GridContainer.defaultProps = {
  filterDashlets: [],
  dashlets: []
};

export default GridContainer;
