import React, { useEffect, useState, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//utils
import { BASE_TENANT_URL } from "../../../apis/apiConstants";

//i18n files
import i18n from "amdi18n-loader!../../nls/i18n";
import apiService from "../../../config/api-config";

const [
    HbrIcon,
    HbrMenuItem,
    HbrMenu,
    HbrMenuLabel,
    HbrNavItem
] = reactWrapper([
    "hbr-icon",
    "hbr-menu-item",
    "hbr-menu",
    "hbr-menu-label",
    "hbr-nav-item",

]);

const MultiTenantMenu = ({ tenantId }) => {
    const [listTenants, setListTenants] = useState([]);
    const [selectedName, setSelectedName] = useState("");
    const dataRef = useRef({
        error: null,
        isLoading: true
    })

    const getTenant = async () => {
        const result = await apiService.getTenantInfo();

        if (result.errorObject instanceof Object) {
            setListTenants([]);
            dataRef.current.error = true;
        } else {
            const selectedTenant = result.data.data.filter((tenantObject) => tenantObject.tenantId === tenantId)
            setSelectedName(selectedTenant[0].name)
            setListTenants(result.data.data);
        }
    }

    useEffect(() => {
        getTenant();
        dataRef.current.isLoading = false;
    }, [])

    const redirectToVMange = (event) => {
        if (event?.currentTarget?.value && event.currentTarget.value !== "provider") {
            window.location.href = `/#/${event.currentTarget.value}${BASE_TENANT_URL}/overview`
        } else {
            window.location.href = `/#${BASE_TENANT_URL}/overview`
        }
    }

    if (dataRef.current.error || dataRef.current.isLoading)
        return (<></>)
    else
        return (
            <HbrNavItem switcher collapsed className="nav-item-section">
                <HbrIcon slot="prefix" name="nav-network-switcher" size="22px" />
                <div slot="content-header">{i18n.megaMenuOperatedLabel}</div>
                <div slot="content">{selectedName}</div>
                <div slot="drawerHeader">{i18n.selectTenantLabel}</div>
                <div slot="menu" className="sub-menu">
                    <HbrMenu shellNav className="menu-item">
                        <HbrMenuItem shellNav value="provider" onClick={(event) => redirectToVMange(event)}>
                            {i18n.multiTenantProvider}
                        </HbrMenuItem>
                        <HbrMenuLabel shellNav>{i18n.multiTenant}</HbrMenuLabel>
                        {listTenants.map((tenant, key) => (
                            <HbrMenuItem className="hbr-menu-item-group" checked={selectedName === tenant.name} key={key} value={tenant.tenantId} onClick={(event) => redirectToVMange(event)}>
                                {tenant.name}
                            </HbrMenuItem>
                        ))}
                    </HbrMenu>
                </div>
            </HbrNavItem>
        )
}

MultiTenantMenu.propTypes = {
    title: PropTypes.string,
    globalFilter: PropTypes.string,
    tenantId: PropTypes.string,
};

export default MultiTenantMenu;