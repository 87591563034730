import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import { matchPath } from "react-router";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import {
  ArrowsClockwise,
  ArrowsOut,
  ListBullets,
  Bell,
  Question,
  UploadSimple
} from "phosphor-react";
import _ from "lodash";
import html2canvas from "html2canvas";

//components
import AppHocReport from "../AppHOCReport";
import AppHoc from "../AppHOC";
import TaskWidget from "./TaskWidget";
import BellWidget from "./BellWidget";
import AiAssistWidget from "./AiAssistWidget";
import { DnxBanner } from "../../loaders/DNXLoader";
import AiFeatureModal from "./AiAssistWidget/AiFeatureModal";
import AiFeedbackModal from "./AiAssistWidget/AiFeedbackModal";
import UserProfile from "./UserProfile";
import HelpWidget from "./HelpWidget";
import HelpModal from "./HelpModal";
import HelpDrawer from "./HelpDrawer";
import SiteList from "../SitesDropdownWidget/SiteList";

//utils
import {
  isDefaultHeader,
  isAccountsPage,
  setLocalStorageFlag,
  isCsvHeader,
  refresh,
  getEditPath,
  setCurrentPath,
  getLocalStorageFlag,
  getCustomListApi,
  isValidFlagsInLocalStorage,
  removeLocalStorageFlag,
  removeItemsLocalStorage,
  getPostLoginNotifications,
  isOverviewPage,
  getStoredPath,
  getStoredTimePeriod
} from "../../utils/common";
import i18n from "amdi18n-loader!../nls/i18n";
import { getCookie } from "../../serviceWorker";
import { initDatadogRum } from "../../monitoring";
import { getTimePeriodELT } from "../../utils/displayTime";
import { BASE_URL, BASE_V_MANAGE_URL, LICENSE_PAGE_URL } from "../../apis/apiConstants";
import { useMount } from "../../utils/genericCommon";

//config
import apiService from "../../config/api-config";
import timeFilterConfig from "../config/time-filter-config";
import { countNotficationPayload } from "./config";

//assets
import aiIcon from "../../assets/ai-assist.svg";

//style
import css from "../genericMagneticStyle.less";

const CommonSitesTable = lazy(() =>
  import("../../views/overview/CommonSitesTable")
);
const MasterTable = lazy(() =>
  import("../../views/circuits/common/MasterTable")
);

let username = i18n.admin;
let pathToAccounts = `${BASE_URL}/accounts`;
const vMangeRedirectURI = getCookie("cl-vManageURI");
const isUIconvergence = getCookie("cl-analytics");
const bannerStyle = { marginLeft: "150px" };
const [HbrIcon, HbrBanner, HbrButton, HbrBadge, HbrTooltip] =
  reactWrapper(["hbr-icon", "hbr-banner", "hbr-button", "hbr-badge", "hbr-tooltip"]);

const AppHeader = (props) => {
  const {
    token,
    urlPrefix,
    globalFilter,
    setOverlays,
    setAccounts,
    errorHeader,
    setSupportRole,
    setOverlayReload,
    setCtrlTimeFilter,
    setSsoLogoutCookie,
    setSelectedOverlay,
    setAvailableFeatureFlags,
    setSelectedOverlayRoles,
    setPostLoginNotifications,
    setAppsWithFamilySLA,
    setSelectedSite
  } = props;

  const history = useHistory();
  const loggedInUser = getLocalStorageFlag("user_email");
  const { notifications, timeFilter } = globalFilter;
  const position = urlPrefix
    ? location.pathname.indexOf(urlPrefix) + urlPrefix.length + 1
    : 0;
  const urlAry = location?.state?.isInValid
    ? []
    : location.pathname.substring(position).split("/");

  const waffleRef = useRef({ isWaffleShown: false });
  const bannerLicenceRef = useRef({ isShown: false });
  const notificationCountRef = useRef({ isShown: false, isError: false, styleWrapper: { width: "3px" } });
  const taskCountRef = useRef({ isShown: false, isError: false });
  const [headerIconState, setHeaderIconState] = useState({
    aiFeature: true,
    aiFeeback: true
  });
  const refModal = useRef(null);
  const refFeedbackModal = useRef(null);
  const assistRef = useRef(true);
  const mount = useMount();

  if (matchPath(location.pathname, { path: `${BASE_URL}/circuits/:circuitName` })) {
    let circuitData = sessionStorage.getItem("circuit");
    if (circuitData) {
      circuitData = JSON.parse(circuitData);
    }
  }

  useEffect(async () => {
    setCurrentPath();
    sessionStorage.removeItem("isGetInfoApiCalled");
    //redirect to accounts page if info api fails
    const redirectToAccountPage = () => {
      removeLocalStorageFlag("currentOverlay");
      removeLocalStorageFlag("currentOverlayName");
      setLocalStorageFlag("isAvailableFeaturesMissing", true);
      history.push(pathToAccounts);
    }

    if (!errorHeader) {
      let overlays = { data: [] };
      let updatedOverlay = [];
      let accounts = [];

      //Get overlays
      try {
        const envVar = process.env;
        if (
          _.isEmpty(envVar.REACT_APP_SKIP_RBAC_DISABLE_RBAC) ||
          envVar.REACT_APP_SKIP_RBAC_DISABLE_RBAC === "FALSE"
        ) {
          const currentOverlay = getLocalStorageFlag("currentOverlay");

          if (
            isAccountsPage() ||
            !currentOverlay ||
            !sessionStorage.getItem("overlays")
          ) {
            let activeFabrics = [];

            removeItemsLocalStorage();
            setLocalStorageFlag("availableFeatures", JSON.stringify({}));
            removeLocalStorageFlag("infoAPIFailed");

            overlays = await apiService.getSmartAccountData(token);

            //get the list of active/activated overlays
            overlays.data.data.map(d => {
              d.overlays.map(data => {
                if (data.overlay_id) {
                  activeFabrics = [
                    ...activeFabrics,
                    {
                      status: data.status,
                      overlay_id: data.overlay_id,
                    }
                  ];
                }
              });
            });

            /**
             * check if cross launch
             * or check overlays  array is a single active/activated fabric
            */
            if (
              (activeFabrics.length === 1 &&
                (activeFabrics[0].status === "ACTIVATED" ||
                  activeFabrics[0].status === "ACTIVE"))
            ) {
              const currentOverlay =
                activeFabrics[0].overlay_id;

              setLocalStorageFlag("currentOverlay", currentOverlay);

              removeLocalStorageFlag("availableFeatures");

              // get info about selected overlay
              const result = await apiService.getOverlayInfo();

              if (!result.errorObject) {
                setLocalStorageFlag(
                  "availableFeatures",
                  JSON.stringify(result.data.features_available)
                );
                setAvailableFeatureFlags(result.data.features_available);
              } else {
                redirectToAccountPage();
              }

              username = getLocalStorageFlag("user_email");
            }
          } else if (!getLocalStorageFlag("availableFeatures")) {
            redirectToAccountPage();
          }

          //default vA, not UI convergence
          if (getLocalStorageFlag("isWaffleShown")) {
            waffleRef.current.isWaffleShown = true;
          }

          if (overlays !== undefined) {
            //when smart account is called first time set overlay data in reducer
            if (!overlays.data.data) {
              overlays = JSON.parse(sessionStorage.getItem("overlays"));
            } else {
              sessionStorage.setItem("overlays", JSON.stringify(overlays));
            }

            username = overlays.data.email;
            setLocalStorageFlag("user_email", username);
            accounts = [...accounts, ...overlays.data.data];

            overlays.data.data.map(d => {
              d.overlays.map(data => {
                if (data.overlay_id) {
                  updatedOverlay = [
                    ...updatedOverlay,
                    {
                      key: data.overlay_id,
                      value: data.org_name,
                      status: data.status,
                      roles: data.roles
                    }
                  ];
                }
              });

              updatedOverlay?.sort((a, b) =>
                a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
              );
            });

            if (overlays.data.enable_rum) {
              setLocalStorageFlag("enable_rum", overlays.data.enable_rum);
              initDatadogRum(overlays.data.rum_config);
            }
            if (overlays.data.is_support_role) {
              setLocalStorageFlag(
                "is_support_role",
                overlays.data.is_support_role
              );
              setSupportRole(true);
            }
            setOverlays(updatedOverlay);
            setAccounts(accounts);

            // Caching implementation for selected overlay
            const cachedOverlay = localStorage.getItem("currentOverlay");
            const cachedOverlayName = localStorage.getItem(
              "currentOverlayName"
            );
            const roles = JSON.parse(localStorage.getItem("roles"));
            const launchDashboard = getStoredPath();

            switch (true) {
              case updatedOverlay.length === 1 &&
                (updatedOverlay[0].status === "ACTIVATED" ||
                  updatedOverlay[0].status === "ACTIVE"): {
                  const currOverlayKey = updatedOverlay[0].key;
                  const currOverlayName = updatedOverlay[0].value;

                  setLocalStorageFlag("currentOverlay", currOverlayKey);
                  setLocalStorageFlag("currentOverlayName", currOverlayName);

                  setSelectedOverlay(
                    localStorage.getItem("currentOverlay") || currOverlayKey,
                    localStorage.getItem("currentOverlayName") || currOverlayName
                  );

                  setSelectedOverlayRoles(updatedOverlay[0].roles);

                  if (!isUIconvergence) {
                    if (vMangeRedirectURI && launchDashboard) {
                      history.push(`${BASE_URL}/${launchDashboard}`);
                    } else {
                      history.push(`${BASE_URL}/overview`);
                    }
                  }

                  break;
                }
              case cachedOverlay && !_.isEmpty(roles) && !vMangeRedirectURI: {
                setSelectedOverlay(cachedOverlay, cachedOverlayName);
                setSelectedOverlayRoles(roles);
                if (vMangeRedirectURI && launchDashboard) {
                  history.push(`${BASE_URL}/${launchDashboard}`);
                } else if (
                  vMangeRedirectURI ||
                  window.location.pathname === `${BASE_URL}/overview`
                ) {
                  history.push(`${BASE_URL}/overview`);
                }
                break;
              }
              default: {
                if (!window.location.href.includes(pathToAccounts)) {
                  history.push(pathToAccounts);
                }
                break;
              }
            }
          }
        }
        //To avoid rendering on accounts page
        // and do not call table availability api when user is not admin in /reports page
        if (localStorage.getItem("currentOverlay") && !location.pathname.includes("/reports")) {
          getPostLoginNotifications(setPostLoginNotifications);
        }
      } catch (e) {
        console.log("error while calling api overlay", e);
      }
      setLocalStorageFlag("PathAnalyticsType", "PA");
    }

    if (localStorage.getItem("currentOverlay") && !isCsvHeader(location)) {
      //do not call table availability api when user is not admin in /reports page
      if (!location.pathname.includes("/reports")) {
        setOverlayReload(true);
        setAppsWithFamilySLA(undefined);
        getCustomListApi(props);
      }
    }

    // check for cross Launch and store logout cookie for seesion Timeout case.
    const logoutCookie = getCookie("lg-vManageURI");
    if (logoutCookie) {
      setSsoLogoutCookie(logoutCookie);
      sessionStorage.setItem("cl-logout", logoutCookie);
    }
  }, [])

  useEffect(async () => {

    //check if flags in local storage are valid and redirect to accounts page
    //We don't require validation when there is a cross-launch
    if (!isValidFlagsInLocalStorage() && !vMangeRedirectURI && !isUIconvergence) {
      setLocalStorageFlag("isAvailableFeaturesMissing", true);
      history.push(`${BASE_URL}/accounts`);
    }

    //convergence api will be called in that block below
    if (getLocalStorageFlag("xsrfToken") && mount.initMount && isUIconvergence && !notificationCountRef.current.isShown) {
      mount.initMount = false;

      getNotificationCountFromVManage();
      getTaskCountFromVManage();

      //get the state of waffle menu in the top right corner
      const waffleMenuResponse = await apiService.getWaffleMenu();
      const compliantResponse = await apiService.getCompliance();

      if ((compliantResponse.errorObject instanceof Object) === false) {
        if (compliantResponse.data.data.status === "Out Of Compliance") {
          setLocalStorageFlag("isCompliant", true);
          bannerLicenceRef.current.isShown = true;
        }
      } else {
        removeLocalStorageFlag("isCompliant");
        bannerLicenceRef.current.isShown = false;
      }

      //waffleEnabled can be either true or false
      if ((waffleMenuResponse.errorObject instanceof Object) === true || waffleMenuResponse?.data?.data[0]?.waffleEnabled === false) {
        waffleRef.current.isWaffleShown = false;
        removeLocalStorageFlag("isWaffleShown", false);

        //else if  error or waffle is disabled than isWaffleShown var stays false
      } else if (waffleMenuResponse?.data?.data[0]?.waffleEnabled) {
        waffleRef.current.isWaffleShown = true;
        setLocalStorageFlag("isWaffleShown", true);
      }
    }

    let div = document.querySelector(".headerApp");
    // Mantain Padding for Normal Header vs Reports Header
    if (getEditPath(location)) {
      if (div) {
        div.style.paddingBottom = "0rem";
      }
    } else {
      if (div) {
        div.style.paddingBottom = "0rem";
        div.style.background = "rgb(247, 247, 247)";
      }
    }

    if (getLocalStorageFlag("currentOverlay") &&
      (getLocalStorageFlag("infoAPIFailed") ||
        !getLocalStorageFlag("availableFeatures") ||
        getLocalStorageFlag("isAvailableFeaturesMissing") ||
        globalFilter.selectedOverlay !==
        getLocalStorageFlag("currentOverlay")) &&
      isOverviewPage()
    ) {
      // get info about selected overlay
      if (!sessionStorage.getItem("isGetInfoApiCalled")) {
        getInfoApiDetails();

        setSelectedOverlay(
          localStorage.getItem("currentOverlay"),
          localStorage.getItem("currentOverlayName")
        );

        if (!globalFilter.overlay_reload_triggered) {
          setAppsWithFamilySLA(undefined);
          getCustomListApi(props);
        }

        let defaultTimePeriodSelected = getStoredTimePeriod();
        let time = timeFilterConfig[defaultTimePeriodSelected].value;

        setSelectedOverlayRoles(
          JSON.parse(getLocalStorageFlag("roles"))
        );
        const timePeriod = getTimePeriodELT(time);

        setCtrlTimeFilter(timePeriod, time);
      }
    }
  }, [
    history,
    timeFilter
  ])

  const getNotificationCountFromVManage = async () => {

    const notificationVMResponse = await apiService.getAlarmCount(countNotficationPayload);

    //check if notification is successful
    if (notificationVMResponse.errorObject instanceof Object) {
      notificationCountRef.current.isError = true;
    } else {
      const count = notificationVMResponse.data.data[0].count;
      //check if notification needs to be shown next to bell icon
      if (count > 0) notificationCountRef.current.isShown = true;

      //case one is when there are more than 99 notification
      //in that case we show 99+
      if (count > 99) {
        notificationCountRef.current.count = "99+";
        notificationCountRef.current.styleWrapper = { width: "8px" };
      } else if (count > 9) {
        //case 2 the count is between 99 and 9 we need to adjust the width of notification
        notificationCountRef.current.count = count;
        notificationCountRef.current.styleWrapper = { width: "3px" };
      } else {
        //case 3 the count is less than 10 we need to adjust the width of notification
        notificationCountRef.current.count = count;
        notificationCountRef.current.styleWrapper = { width: "1px" };
      }
    }
  }

  const getTaskCountFromVManage = async () => {

    const taskVMResponse = await apiService.getTaskSummary();

    //check if notification is successful
    if (taskVMResponse.errorObject instanceof Object) {
      taskCountRef.current.isError = true;
    } else {

      const count = taskVMResponse.data.data.filter(d => d.status !== "done").length;

      if (count > 0) taskCountRef.current.isShown = true;

      taskCountRef.current.count = count >= 99 ? "99+" : count;
    }
  }

  const toggleFullscreen = () => {
    const elem = document.getElementById("full-screen-content");
    elem.style.overflow = "auto";
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullScreen) {
      elem.msRequestFullScreen();
    }
  }

  const captureScreen = async () => {
    const elem = document.getElementById("main-content");

    const img = await html2canvas(elem, {
      width: elem.scrollWidth,
      height: elem.scrollHeight,
      useCORS: true,
      logging: false
    });
    const contentDataURL = img.toDataURL("image/png");
    // window.location.href = contentDataURL.replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement("a");
    downloadLink.href = contentDataURL;
    downloadLink.style.display = "none";
    const path = window.location.pathname.split("/").slice(-1)[0];
    downloadLink.setAttribute("download", `${path}.png`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  const onRefresh = async (e) => {
    e.preventDefault();
    //check if availableFeatures flag is present
    //if not redirect to accounts page
    if (!getLocalStorageFlag("availableFeatures")) {
      removeLocalStorageFlag("currentOverlay");
      removeLocalStorageFlag("currentOverlayName");
      setLocalStorageFlag("isAvailableFeaturesMissing", true);
      history.push(pathToAccounts);
    }
    refresh(props);
    if (localStorage.getItem("currentOverlay")) {
      //To avoid rendering on accounts page
      getPostLoginNotifications(setPostLoginNotifications);
    }
  }

  const openAssist = async () => {
    const cloudService = await apiService.getCloudServices();
    const feature = await apiService.getvManageFeature();

    //check both apis for success ok 200 code
    if ((feature.errorObject instanceof Object) === false && (cloudService.errorObject instanceof Object) === false) {
      //check both apis return enabled is true
      if (feature.data?.enabled === true && cloudService?.data?.data[0]?.enabled === true) {
        //show modal with ai widget
        refModal.current.element.show();
        setHeaderIconState({ aiAssist: true })
      } else if (cloudService?.data?.data[0]?.enabled === false) {
        //show modal cloud service are not enabled yet
        refModal.current.element.show();
      } else if (feature.data?.enabled === false) {
        //show modal ai widget is for limited customers
        refFeedbackModal.current.element.show();
      }
    }

    assistRef.current = true;

  }

  const openBell = () => {
    setHeaderIconState({ bell: true })
  }

  const closeBell = () => {
    setHeaderIconState({ bell: false })
  }

  const closeAssist = () => {
    setHeaderIconState({ aiAssist: false });
    assistRef.current = false;
  }

  const openHelpModal = (isOpen) => {
    setHeaderIconState({ helpModal: isOpen })
  }

  const openHelpDrawer = (isOpen) => {
    setHeaderIconState({ helpDrawer: isOpen })
  }

  //get details about feature available from info api call
  //set or remove necessary sessions or cookies
  //handle errors if info api fails, if so redirect to accounts page
  //and display error banner
  const getInfoApiDetails = async () => {
    if (getLocalStorageFlag("isAvailableFeaturesMissing")) {
      removeLocalStorageFlag("isAvailableFeaturesMissing");
      sessionStorage.removeItem("isGetInfoApiCalled");
    } else {
      sessionStorage.setItem("isGetInfoApiCalled", true);
    }

    removeLocalStorageFlag("infoAPIFailed");
    removeLocalStorageFlag("availableFeatures");

    let result;

    try {
      result = await apiService.getOverlayInfo();

      if (!result.errorObject) {
        setLocalStorageFlag(
          "availableFeatures",
          JSON.stringify(result.data.features_available)
        );
        setAvailableFeatureFlags(result.data.features_available);
      } else {
        setLocalStorageFlag(
          "infoAPIFailed",
          JSON.stringify({
            isError: true,
            message: `${result?.errorObject?.message}.
              Failed with error code ${result?.errorObject?.errorCode}.
              Try again. Reload the page.`
          })
        );
        removeLocalStorageFlag("currentOverlay");
        removeLocalStorageFlag("currentOverlayName");
        history.push(pathToAccounts);
      }
    } catch (e) {
      console.log("Info api failed ", e);
    }
  }

  //On click of notification close icon
  const onClosed = async (e) => {
    let uuidArr = [];
    uuidArr.push(e.target.__config.uuid);
    let payload = {
      notification_uuid: uuidArr
    };
    let notificationStatus = await apiService.updateNotifications(payload);
    if (notificationStatus?.status === 200) {
      setPostLoginNotifications([]);
    }
  }

  //Method to render post-login notifications
  const renderNotification = () => {
    const { notifications } = globalFilter;
    return notifications.map((item, key) => {
      if (key === 0) {
        //To render only one notification
        let bannerConfig = {
          message: item.notification_msg,
          type:
            item.notification_criticality === "info"
              ? i18n.information
              : i18n.warning,
          uuid: item.notification_uuid
        };
        return (
          <div className="notification-banner" key={key}>
            <DnxBanner
              className={css["dnx-banner-custom"]}
              key={key}
              close={e => onClosed(e)}
              config={bannerConfig}
              hideRemoveButton={!item.notification_is_dismissiable}
            ></DnxBanner>
          </div>
        );
      }
    });
  }

  // on task icon click
  const openTask = () => {
    setHeaderIconState({ task: true })
  }

  const closeTask = () => {
    setHeaderIconState({ task: false })
  }


  const onHeaderBrandingClick = async (e) => {
    e.preventDefault();
    const clVmanageURI = getCookie("cl-analytics");
    if (clVmanageURI) {
      window.location.href = `${BASE_V_MANAGE_URL}/overview`;
    } else if (!matchPath(location.pathname, { path: `${BASE_URL}/overview` })) {
      history.push(`${BASE_URL}/overview`);
    }
  }

  const handleClick = () => {
    window.location.href = LICENSE_PAGE_URL;
  }

  return (
    <div className={css["app-header"]}>
      {sessionStorage.getItem("overlays") &&
        <>
          <Suspense fallback={null}>
            <CommonSitesTable data={props} />
          </Suspense>
          <Suspense fallback={null}>
            <MasterTable />
          </Suspense>
          <SiteList />
        </>
      }

      {headerIconState?.task && <TaskWidget closeTask={closeTask} />}
      {headerIconState?.bell && <BellWidget closeBell={closeBell} setSelectedSite={setSelectedSite} />}
      {headerIconState?.aiAssist && <AiAssistWidget closeAssist={closeAssist} assistRef={assistRef} />}
      {headerIconState?.aiFeature && <AiFeatureModal refModal={refModal} />}
      {headerIconState?.aiFeeback && <AiFeedbackModal refFeedbackModal={refFeedbackModal} />}
      {notifications.length && localStorage.getItem("currentOverlay")
        ? renderNotification()
        : null}
      {(!isDefaultHeader(location) &&
        !isCsvHeader(location) &&
        urlAry[0] !== "") ||
        (errorHeader && getCookie("okta-oauth-state")) ? (
        <hbr-shell-header>
          <a slot="logo-name" className={isUIconvergence ? "no-header-branding" : "header-branding"} onClick={(e) => onHeaderBrandingClick(e)}>
            <hbr-icon name="cisco"></hbr-icon>
            <div className="product-name">{i18n.productLabel}</div>
          </a>
          <div slot="utilities">
            {/* Header icons for non converged ui*/}
            {!isUIconvergence &&
              <>
                <HbrTooltip
                  id="tooltip-refresh"
                  content={i18n.refresh}
                  placement="top"
                >
                  <HbrButton
                    onClick={e => onRefresh(e)}
                    noPadding
                    sentiment="header"
                  >
                    <ArrowsClockwise size={22} weight="bold" />
                  </HbrButton>
                </HbrTooltip>
                <HbrTooltip
                  id="tooltip-fullscreen"
                  content={i18n.fullScreen}
                  placement="top"
                >
                  <HbrButton
                    onClick={toggleFullscreen}
                    noPadding
                    sentiment="header"
                  >
                    <ArrowsOut size={22} weight="bold" />
                  </HbrButton>
                </HbrTooltip>
                <HbrButton
                  onClick={captureScreen}
                  noPadding
                  sentiment="header"
                  slot="menu"
                >
                  <UploadSimple size={22} weight="bold" />
                </HbrButton>
                <HbrButton
                  noPadding
                  sentiment="header"
                  slot="menu"
                  disabled
                >
                  <Question size={22} weight="bold" />
                </HbrButton>
                <HbrButton
                  noPadding
                  sentiment="header"
                  slot="menu"
                  disabled
                >
                  <Bell size={22} weight="bold" />
                </HbrButton>

              </>
            }
            {/* Header icons for converged ui*/}
            {isUIconvergence &&
              <>
                <HbrButton
                  onClick={openAssist}
                  noPadding
                  sentiment="header"
                  size="medium"
                  slot="menu"
                >
                  <HbrIcon
                    src={aiIcon}
                    staticColors="true"
                    size="24px"
                  ></HbrIcon>
                </HbrButton>
                <HbrButton
                  onClick={openTask}
                  sentiment="header"
                  slot="menu"
                  size="small"
                >
                  <ListBullets size={24} weight="bold" />
                  {(taskCountRef.current.isShown && !taskCountRef.current.isError) &&
                    <span className="notification-badge-wrapper">
                      <HbrBadge pill inline size="small" sentiment="danger" className="notification-badge">
                        <div id="notification-tag">{taskCountRef.current.count}</div>
                      </HbrBadge>
                    </span>}
                </HbrButton>
                <HelpWidget openHelpModal={openHelpModal} openHelpDrawer={openHelpDrawer} />
                <HbrButton
                  onClick={openBell}
                  noPadding
                  sentiment="header"
                  slot="menu"
                >
                  <Bell size={24} weight="bold" />
                  {(notificationCountRef.current.isShown && !notificationCountRef.current.isError) &&
                    <span className="notification-badge-wrapper" style={notificationCountRef.current.styleWrapper}>
                      <HbrBadge pill inline size="small" sentiment="danger" className="notification-badge">
                        <div id="notification-tag">{notificationCountRef.current.count}</div>
                      </HbrBadge>
                    </span>
                  }
                </HbrButton>
              </>
            }
            <hbr-menu-divider vertical sentiment="header"></hbr-menu-divider>
            {loggedInUser && <UserProfile loggedInUser={loggedInUser} waffleRef={waffleRef} />}
          </div>
        </hbr-shell-header>
      ) : (
        // END: Harbor shell header
        ""
      )}
      {bannerLicenceRef.current.isShown &&
        <div className={css["compliance"]}>
          <div className="outer-banner">
            <div className="banner" style={bannerStyle}>
              <HbrBanner
                sentiment="danger"
                variant="fill"
                open
              >
                <HbrIcon slot="icon" name="hbr-error-filled"></HbrIcon>
                <span className="banner-text">{"The network is out of compliance due to licensing, please "}
                  <HbrButton variant="text" onClick={handleClick}><span id="click-here">{" click here "}</span></HbrButton>
                  {" for more actions."}</span>
              </HbrBanner>
            </div>
          </div>
        </div>}
      {headerIconState.helpModal && <HelpModal openHelpModal={openHelpModal} {...props} />}
      {headerIconState.helpDrawer && <HelpDrawer openHelpDrawer={openHelpDrawer} />}
    </div>
  );

}

AppHeader.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setSelectedOverlay: PropTypes.func.isRequired,
  setSsoLogoutCookie: PropTypes.func.isRequired,
  setOverlays: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  urlPrefix: PropTypes.string.isRequired,
  setSelectedOverlayRoles: PropTypes.func.isRequired,
  setAccounts: PropTypes.func.isRequired,
  setOverlayReload: PropTypes.func.isRequired,
  token: PropTypes.string,
  errorHeader: PropTypes.string,
  setSupportRole: PropTypes.func.isRequired,
  setAltoProps: PropTypes.func,
  setAvailableFeatureFlags: PropTypes.func,
  setCtrlTimeFilter: PropTypes.func,
  setAggregateAppData: PropTypes.func.isRequired,
  setAppsWithFamilySLA: PropTypes.func.isRequired,
  setPostLoginNotifications: PropTypes.func,
  setAnomalyRefresh: PropTypes.func.isRequired,
  closeTask: PropTypes.func,
  setSelectedSite: PropTypes.func,
  icon: PropTypes.object
};

export default AppHocReport(AppHoc(AppHeader));
