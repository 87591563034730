import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";
import { getDefaultTimeFilter } from "../../utils/query-utils";
import { setRumUser, updateRumSite } from "../../monitoring";

const reduxContext = createContext();
const storeKey = "vanalytics.app.globalReport";
const currentOverlay = "currentOverlay";
const currentSite = "currentSite";

export default reduxContext;

const initialState = {
  overlayId: "",
  timePeriodSelected: "24",
  timeFilter: {},
  customTime: {},
  workflowState: {}
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_WORKFLOW_STATE: {
      const updatedWorkflowState = {
        ...state.workflowState,
        ...payload
      };
      localStorage.removeItem("workflow");
      localStorage.setItem("workflow", JSON.stringify(updatedWorkflowState));
      return {
        ...state,
        ...{ workflowState: updatedWorkflowState }
      };
    }

    case ACTIONS.SET_OVERLAY_ID: {
      localStorage.setItem(currentOverlay, payload);
      localStorage.setItem(currentSite, -1);
      setRumUser(payload);
      updateRumSite(-1);
      return {
        ...state,
        overlayId: payload
      };
    }
    case ACTIONS.SET_CTRL_TIME_FILTER: {
      const updatedFilter =
        state.siteId > 0
          ? {
              ...payload.time_period,
              ...{
                filter: {
                  site_id: state.siteId
                }
              }
            }
          : getDefaultTimeFilter(payload);
      return {
        ...state,
        timeFilter: updatedFilter,
        timePeriodSelected: payload.timePeriodSelected
      };
    }

    case ACTIONS.SET_CUSTOM_TIME:
      return {
        ...state,
        selectedCustomTime: payload
      };
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SET_OVERLAY_ID,
    ACTIONS.SET_CTRL_TIME_FILTER,
    ACTIONS.SET_CUSTOM_TIME,
    ACTIONS.SET_WORKFLOW_STATE
  ]
});
