import React, { useState, useEffect } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";

//components
import NoDataAvailable from "../../../common/NoDataAvailable";

import { arrayToObjectExtended } from "../../../utils/common";

//utils
import { BASE_URL } from "../../../apis/apiConstants";

//config
import apiService from "../../../config/api-config";
import { BASE_V_MANAGE_URL } from "../../../apis/apiConstants";
import { request } from "../config";

//i18n files
import i18n from "amdi18n-loader!../../nls/i18n";

//style
import css from "../../genericMagneticStyle.less";

const [
    HbrButton,
    HbrBadge,
    HbrLink,
    HbrIcon,
    HbrDrawer,
    HbrAccordion
] = reactWrapper([
    "hbr-button",
    "hbr-badge",
    "hbr-link",
    "hbr-icon",
    "hbr-drawer",
    "hbr-accordion"
]);

const BellWidget = ({ setSelectedSite, closeBell }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const history = useHistory();
    const drawerID = "notification-drawer";

    const getData = async () => {
        const deviceNotifResult = await apiService.getNotificationVManage(request);
        const waniNotificResult = await apiService.getWaniNotifications();

        if (deviceNotifResult.errorObject instanceof Object || waniNotificResult.errorObject instanceof Object) {
            //habndle error for  device and wani notification
            setData({
                deviceNotifications: [],
                waniNotifications: [],
                sites: []
            });
        } else {
            const sites = arrayToObjectExtended(waniNotificResult.data.recommendations, "siteName")

            setData({
                deviceNotifications: deviceNotifResult.data.data,
                waniNotifications: waniNotificResult.data.recommendations,
                sites: sites
            })
        }
        setIsLoading(true);
    }

    const handleCloseClick = (event) => {
        if (event.target.id === drawerID) closeBell(true);
    }

    useEffect(() => {
        getData();
    }, [])

    const viewAll = () => {
        //go to logs page
        window.location.href = `${BASE_V_MANAGE_URL}/logs`;
    }

    const redirectToWani = (event, single) => {
        if (single === "single-site") {
            setSelectedSite(data.sites[event.currentTarget.textContent].siteId)
        } else {
            setSelectedSite(-1);
        }

        history.push(`${BASE_URL}/predictivenetworks`);
    }

    const getNotifications = (notifications, title, notificationKey) => {
        return (
            <div className="accordion-notification">
                <HbrAccordion summary={title} open={true} triggerposition="left">
                    <div slot="label" className="bell-accordion-header-icon">
                        <HbrButton
                            noPadding
                            sentiment="header"
                            size="medium"
                            slot="menu"
                        >
                            <HbrIcon
                                name="nav-applications"
                                staticColors="true"
                                size="20px"
                            >
                            </HbrIcon>
                        </HbrButton>
                        <span>{title}</span>
                        <span className="bell-accordion-badge">
                            <HbrBadge pill size="small">{notifications.length}</HbrBadge>
                        </span>
                    </div>
                    <div>
                        {notifications && notifications.map((value, key) =>
                            <div className="bell-accordion-row" key={key}>
                                <div className="bell-accordion-text" onClick={notificationKey === "siteName" ? (e) => redirectToWani(e, "single-site") : viewAll}>
                                    {value[notificationKey]}
                                </div>
                                <div>
                                    <HbrBadge pill size="small">{value.count}</HbrBadge>
                                </div>
                            </div>
                        )}
                        <div className="bell-accordion-view-all">
                            <HbrLink target="_parent" onClick={notificationKey === "siteName" ? (e) => redirectToWani(e) : viewAll}>{i18n.notificationViewAll}</HbrLink>
                        </div>
                    </div>
                </HbrAccordion>
            </div>
        )
    }
    const handleClick = () => {
        window.location.href = `${BASE_V_MANAGE_URL}/logs`;
    }

    if (!isLoading)
        return <></>

    return (
        <div className={css["bell-drawer"]}>
            <HbrDrawer
                label={i18n.notificationLabel}
                id={drawerID}
                open={true}
                onHbr-hide={handleCloseClick}
            >
                <div className="create-notification">{"To create Alarm Notification(s), "}
                    <HbrButton variant="text" onClick={handleClick}><span id="click-here">{" click here "}</span></HbrButton>
                </div>
                {
                    data.waniNotifications.length > 0 &&
                    <div className="bell-accordion">
                        {getNotifications(data.waniNotifications, i18n.notificationWani, "siteName")}
                    </div>
                }
                {
                    data.deviceNotifications.length > 0 &&
                    <div className="bell-accordion">
                        {getNotifications(data.deviceNotifications, i18n.notificationTitle, "host-name")}
                    </div>
                }
                {
                    data.deviceNotifications.length === 0 && data.waniNotifications.length === 0 &&
                    <div className="no-data">
                        <NoDataAvailable />
                    </div>
                }
            </HbrDrawer>
        </div>
    )
}

BellWidget.propTypes = {
    closeBell: PropTypes.func,
    setSelectedSite: PropTypes.func
}

export default BellWidget;