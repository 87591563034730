import { PropTypes } from "prop-types";

import ChatForm from "../ChatForm";
import ChatMessages from "../ChatMessages";
import Placeholder from "../Placeholder";
import GeneratingMessages from "../GeneratingMessages";

const ChatPanel = (props) => {
    const { selectedThread } = props;
    return (
        <div slot="chat-panel" className="hbr-css__chat-panel">
            {selectedThread ? (
                selectedThread.message ? (
                    <ChatMessages {...props} />
                ) : (
                        <GeneratingMessages {...props} />
                    )
            ) : (
                    <Placeholder {...props} />
                )}
            <ChatForm {...props} />
        </div>
    );
}

ChatPanel.propTypes = {
    selectedThread: PropTypes.string,
}
export default ChatPanel