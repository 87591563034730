import React, { Fragment, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//style
import css from "../genericMagneticStyle.less";

//components
import AppHoc from "../AppHOC";
import ManagerTab from "./ManagerTab";
import Menu from "./Menu";
import MultiTenantMenu from "./MultiTenantMenu";

//config
import { getNavConfig } from "../../config/nav-config";
import apiService from "../../config/api-config";
import { BASE_URL } from "../../apis/apiConstants";

//utils
import {
  isAdminRole,
  isReportingApi,
  getLocalStorageFlag,
  setLocalStorageFlag,
  getConvergedCookie
} from "../../utils/common";
import { getCookie } from "../../serviceWorker";
import { useMount } from "../../utils/genericCommon";

//i18n files
import i18n from "amdi18n-loader!../nls/i18n";

const availableFeatures = JSON.parse(getLocalStorageFlag("availableFeatures"));

const [
  HbrMenuDivider,
  HbrShellNav,
  HbrNavItem,
  HbrIcon,
  HbrMenu,
  HbrMenuItem
] = reactWrapper([
  "hbr-menu-divider",
  "hbr-shell-nav",
  "hbr-nav-item",
  "hbr-icon",
  "hbr-menu",
  "hbr-menu-item"
]);

const MegaMenu = (props) => {
  const { globalFilter } = props;
  const { selectedOverlayName } = globalFilter;
  const [vManageNavList, setVManageNavList] = useState();
  const [navconfig, setNavConfig] = useState(getNavConfig());
  const isUIconvergence = getCookie("cl-analytics");
  const mount = useMount();
  const clVmanageURI = getCookie("cl-vManageURI");

  const getFeatureFlags = (featureName) => {

    // get feature flags from redux store after click on overlay from accounts page  
    if (props.globalFilter.availableFeature && Object.keys(props.globalFilter.availableFeature).length > 0) {
      return props.globalFilter.availableFeature && props.globalFilter.availableFeature[featureName];
    } else if (availableFeatures && Object.keys(availableFeatures).length > 0) {
      // get feature flags from local storage (In page refresh or default load)
      return availableFeatures && availableFeatures[featureName];
    } else {
      // get feature flags from local storage (In page refresh or default load)   
      return false;
    }
  }

  const showFeature = (feature, path, name) => {

    // check feature is false then add feature object to tabconfig array
    if (navconfig[0]?.submenu.some(item => item.text === feature) === false) {
      navconfig[0]?.submenu.push({
        text: feature,
        icon: "",
        feature: name,
        path: `${BASE_URL}${path}`,
      });
    }
  }

  const hideFeature = (feature) => {
    // check feature is true then remove feature object from tabconfig array
    if (navconfig[0]?.submenu?.some(item => item.text === feature)) {
      navconfig[0]?.submenu.splice(navconfig[0]?.submenu.findIndex(item => item.text === feature), 1);
    }
  }

  const megaMenuTab = () => {

    let securityFeature = getFeatureFlags("security");
    let trafficLogFeature = getFeatureFlags("oda-traffic-logs");

    // check security tab is available in tabconfig array or not
    if (securityFeature) {
      showFeature(i18n.threatIntelligence, "/threatintelligence", "talos");
    } else {
      hideFeature(i18n.threatIntelligence);
    }

    //traffic logs tab
    if (trafficLogFeature) {
      showFeature(i18n.trafficLogs, "/traffic-logs", "trafficLogs");
    } else {
      hideFeature(i18n.trafficLogs);
    }
  }

  useEffect(async () => {
    const { selectedOverlay } = globalFilter;
    const isOverlayChanged =
      getLocalStorageFlag("currentOverlay") !== selectedOverlay;
    const roles = JSON.parse(getLocalStorageFlag("roles"));
    let navPanelList;

    if (isUIconvergence && mount.initMount && clVmanageURI && !globalFilter.menuState?.data) {
      const navigationCookie = getLocalStorageFlag("cl-navigation");
      const navArray = navigationCookie && JSON.parse(navigationCookie) ?
        JSON.parse(navigationCookie) :
        null;

      if (navArray) {
        //first we check if "cl-navigation" cookie exists and get the nav menu
        //if does not exists call navigation api
        setVManageNavList(navArray)
        props.setMenuState({ data: navArray });

      } else if (navArray === null) {
        //this block is a temporary fix, there is a use case where there is no cookie present in vManage
        //we should have cookies all the time
        setVManageNavList([]);
      } else {
        navPanelList = await apiService.getNavigationPanelList();
        if (!navPanelList.errorObject) {
          setVManageNavList(navPanelList.data.data);
          props.setMenuState({ data: navPanelList.data.data });
        } else {
          props.setMenuState({ isError: true });
          setVManageNavList([]);
        }
      }

    } else if (globalFilter.menuState?.data) {
      setVManageNavList(globalFilter.menuState?.data);
    } else {
      setVManageNavList([]);
    }

    // Added for role based navigation visibility
    if (isOverlayChanged && isAdminRole(roles)) {
      setNavConfig(getNavConfig(true));
    } else if (isOverlayChanged && !isAdminRole(roles)) {
      setNavConfig(getNavConfig(false));
    }

    if (mount.initMount && isAdminRole(roles)) {
      mount.initMount = false;
      setNavConfig(getNavConfig(true))
    }
  }, [])

  useEffect(() => {
    megaMenuTab();
  }, []);

  useEffect(() => {
    megaMenuTab();
  }, [props.globalFilter.availableFeature]);

  const generateLink = navItem => {

    if (navItem.path === "" && clVmanageURI) {
      // use case for enabling 'Cisco SD-WAN Center' link
      return <ManagerTab {...props} navItem={navItem} key={Math.random()} redirectUrl={clVmanageURI} />
    } else if (navItem.path) {
      //link to submenu navigation link
      if (navItem.submenu) {
        return (<Menu navItem={navItem} key={Math.random()} collapsed />)
      } else {
        //link to parent navigation link
        return (<Menu navItem={navItem} key={Math.random()} collapsed />)
      }
    } else {
      return <div key={Math.random()}></div>;
    }
  };

  const redirectToAccount = () => {
    // remove traffic logs feature from availableFeatures object
    //need to show traffic logs tab in mega menu with no delay
    const availableFeatures = JSON.parse(getLocalStorageFlag("availableFeatures"));
    delete availableFeatures["oda-traffic-logs"];
    setLocalStorageFlag("availableFeatures", JSON.stringify(availableFeatures));
    props.history.push(`${BASE_URL}/accounts`)//redirect to account page
  }

  if (isUIconvergence) {
    const tenantId = getConvergedCookie("tenantId");

    if (clVmanageURI && vManageNavList) {
      return (
        <div className={css.megaMenu}>
          <div className="shell-menu">
            <HbrShellNav collapsed disabled disableCollapseTooltip={true} id="docs__shell-nav">
              {tenantId && <MultiTenantMenu {...props} title={i18n.megaMenuOperatedLabel} tenantId={tenantId} />}
              {vManageNavList.map((data, index) => (
                <Menu navItem={data} key={index} collapsed />
              ))}
            </HbrShellNav>
          </div>
        </div>
      )
    } else if (clVmanageURI) {
      return (<></>)
    }
  } else {
    return (
      <div className={css.megaMenu}>
        {window.location.pathname !== props.pathToAccount &&
          !isReportingApi() && (
            <div className={css.megaMenu}>
              <div className="shell-menu">
                <HbrShellNav collapsed disableCollapseTooltip={true} id="docs__shell-nav">
                  {clVmanageURI !== undefined && clVmanageURI.includes("/") ?
                    <div className="static-menu">{selectedOverlayName}</div> :
                    <HbrNavItem switcher collapsed className="nav-item-section">
                      <HbrIcon slot="prefix" name="nav-organization-switcher" size="22px" />
                      <div slot="content-header">{i18n.megaMenuFabricLabel}</div>
                      <div slot="content">{selectedOverlayName}</div>
                      <div slot="drawerHeader">{i18n.megaMenuFabricsLabel}</div>
                      <div slot="menu" className="sub-menu">
                        <HbrMenu shellNav className="menu-item">
                          <HbrMenuItem shellNav value="provider" onClick={redirectToAccount}>
                            {i18n.megaMenuViewFabricLabel}
                          </HbrMenuItem>
                        </HbrMenu>
                      </div>
                    </HbrNavItem>}
                  <HbrMenuDivider className="nav-divider" collapsed></HbrMenuDivider>
                  {navconfig.map(navItem => {
                    return navItem.submenu ? (
                      generateLink(navItem)
                    ) : navItem.path === "" ? (
                      clVmanageURI ? (
                        generateLink(navItem)
                      ) : (
                        <Fragment key={Math.random()} />
                      )
                    ) : (
                      generateLink(navItem)
                    );
                  })}
                </HbrShellNav>
              </div>
            </div>
          )}
      </div>
    );
  }
};

MegaMenu.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  pathToAccount: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
  setMenuState: PropTypes.func
};
export default AppHoc(MegaMenu);