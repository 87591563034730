
import React from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";

import i18n from "amdi18n-loader!../../nls/i18n";

const [HbrButton, HbrModal] =
    reactWrapper(["hbr-button", "hbr-modal"]);

const HelpModal = ({ openHelpModal, about }) => {

    const closeModal = () => {
        openHelpModal(false);
    }

    return (
        <HbrModal label={i18n.helpModalTitle} open={true}>
            <div className="hbr-type-body3">{i18n.helpModalPlatform}</div>
            <div className="subtitle">{about.version}</div>
            <br />
            <div className="hbr-type-body3">{i18n.helpModalVersion}</div>
            <div className="subtitle">{about.applicationVersion}</div>
            <br />
            <div className="hbr-type-body3">{i18n.helpModalServer}</div>
            <div className="subtitle">{about.applicationServer}</div>
            <br />
            <div className="hbr-type-body3">{i18n.helpModalTime}</div>
            <div className="subtitle">{about.time}</div>
            <br />
            <div className="hbr-type-body3">{i18n.helpModalZone}</div>
            <div className="subtitle">{about.timeZone}</div>
            <br />
            <div className="hbr-type-body3">{about.copyright}</div>

            <div
                slot="footer"
                className="hbr-css__layout-row-md hbr-css__layout-justify-end"
            >
                <HbrButton variant="text" onClick={closeModal}>
                    {i18n.helpModalClose}
                </HbrButton>
            </div>
        </HbrModal>
    )


}

HelpModal.propTypes = {
    openHelpModal: PropTypes.func,
    about: PropTypes.object
}

export default HelpModal;