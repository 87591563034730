import React, { useState, useEffect } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import Aside from "./Aside";
import ChatPanel from "./ChatPanel";
import Header from "./Header";

//style
import css from "../../genericMagneticStyle.less";

//config
import apiService from "../../../config/api-config";

import { suggestions } from "./defaultSuggestions";

const [HbrChat, HbrWindow] = reactWrapper(["hbr-chat", "hbr-window"]);

const AiAssistWidget = ({ assistRef, closeAssist }) => {
    const locationPathName = location.pathname;
    const pathUrl = locationPathName.substring(1, locationPathName.length - 1);
    const [chatSessionId, setChatSessionId] = useState("");
    const [sessions, setSessions] = useState([]);
    const [suggestedQuestions, setSuggestedQuestions] = useState([])
    const [selectedThread, setSelectedThread] = useState(null);
    // state to sync when aside collapses either by clicking on menu button or auto collapse by resize
    const [collapsed, setCollapsed] = useState(false);
    const [, setOpen] = useState(assistRef.current);
    const [text, setText] = useState("");
    const [dialogId, setDialogId] = useState("");

    const getSessionAndSuggestionsData = async () => {

        const payload = { pageUrl: pathUrl };

        //1 post session to set session id
        const sessionPost = await apiService.postvManageSession({});

        if (sessionPost?.data?.status === "SUCCESS") {
            //i.e. response {"status":"SUCCESS","chatSessionId":"50b7be02-a8a4-4b67-a8b6-cb6024460ca3"}
            setChatSessionId(sessionPost?.data?.chatSessionId)
        }

        //2 get list of suggesions that will be display on cards in the main panel
        const suggestedQuestionsResponse = await apiService.getvManageSuggestion(payload);

        if (suggestedQuestionsResponse.errorObject instanceof Object) {
            setSuggestedQuestions(suggestions);
        } else {
            setSuggestedQuestions(suggestedQuestionsResponse?.data?.suggestions);
        }

        //3 get sessions of past searches and display on aside panel
        const sessionGet = await apiService.getvManageSession();

        if (sessionGet.errorObject instanceof Object) {
            setSessions([]);
        } else {
            setSessions(Array.isArray(sessionGet?.data?.sessions) ? sessionGet?.data?.sessions[0] : []);
        }
    }

    useEffect(() => {
        getSessionAndSuggestionsData();
    }, [])


    const handleMenuButtonClick = () => {
        setCollapsed(!collapsed);
    }

    const handleCloseClick = () => {
        closeAssist(false);
        setOpen(false);
    }

    const handleResize = (collapsed) => {
        setCollapsed(collapsed);
    }

    const handleInitialFocus = (event) => {
        event.preventDefault();
        // Get a reference to the input text area
        // querySelector is used here, use any standard way to access refs in your framework of choice
        document.querySelector("#chatInputTextarea").setFocus();
    }

    const handleNewThreadClick = () => {
        setSelectedThread(null);
    }

    const setInput = async (event) => {
        const value = event.currentTarget.value;

        if (value) {
            //check previous value the user search, 
            //and make sure its not the same one that the user searched already for
            if (value !== text) {
                setText(value);
                setDialogMessages(value);
            }
        }
    }

    const setDialogMessages = async (input) => {

        setSelectedThread(input);

        //4 show answer for AI chat for the question SYSTEM asked
        const dialogResponse = await apiService.getvManageDialog({
            chatSessionId: chatSessionId, //get that chat session from first session api call
            "data": input,
            lang: "Eng",
            url: pathUrl
        });

        //5 get sessions of past searches again
        // now  update the aside panel with new sessions
        const sessionGet = await apiService.getvManageSession();

        if (sessionGet.errorObject instanceof Object) {
            setSessions([])
        } else {
            setSessions(Array.isArray(sessionGet?.data?.sessions) ? sessionGet?.data?.sessions[0] : []);
        }

        if (dialogResponse.errorObject instanceof Object) {
            setSelectedThread({ error: dialogResponse.errorObject, msg: input });
        } else {
            setSelectedThread(Object.assign({ query: input }, dialogResponse?.data));
            setDialogId(dialogResponse?.data.id);
        }
    }

    return (
        <div className={css["assist-icon-drawer"]}>
            <HbrWindow open={assistRef.current} onHbr-initial-focus={handleInitialFocus}>
                <Header
                    collapsed={collapsed}
                    handleMenuButtonClick={handleMenuButtonClick}
                    handleCloseClick={handleCloseClick}
                    handleNewThreadClick={handleNewThreadClick}
                />
                <HbrChat
                    collapsed={collapsed}
                    onHbr-collapse={() => {
                        handleResize(true);
                    }}
                    onHbr-expand={() => {
                        handleResize(false);
                    }}
                >
                    {/** Aside */}
                    <Aside
                        sessions={sessions}
                        collapsed={collapsed}
                        setSelectedThread={setSelectedThread}
                        handleNewThreadClick={handleNewThreadClick}
                    />

                    {/** Chat Panel - Main */}
                    <ChatPanel
                        selectedThread={selectedThread}
                        setDialogMessages={setDialogMessages}
                        suggestedQuestions={suggestedQuestions}
                        setInput={setInput}
                        chatSessionId={chatSessionId}
                        dialogId={dialogId}
                    />
                </HbrChat>
            </HbrWindow>
        </div >
    )
}

AiAssistWidget.propTypes = {
    assistRef: PropTypes.object,
    closeAssist: PropTypes.func
}

export default AiAssistWidget;