import { PropTypes } from "prop-types";

//i18n files
import i18n from "amdi18n-loader!../../../nls/i18n";

const Header = ({
    handleMenuButtonClick,
    handleCloseClick,
    collapsed
}) => {
    return (
        <div slot="header" className="hbr-css__layout-row-xs hbr-css__chat-header">
            <span className="hbrchat-small-view-menu-button">
                <hbr-tooltip
                    anchor="#__chat-header-nav-icon"
                    content={collapsed ? "Expand" : "Collapse"}
                    placement={collapsed ? "right" : "bottom"}
                />
                <hbr-button
                    variant="text"
                    size="small"
                    sentiment="neutral"
                    onClick={handleMenuButtonClick}
                    id="__chat-header-nav-icon"
                >
                    <hbr-icon
                        name="nav"
                        sentiment="neutral"
                        size="20px"
                    />
                </hbr-button>
            </span>
            <div className="hbr-css__text-heading-lg-bold">{i18n.cornerTitle}</div>
            <hbr-tag size="small" accent="var(--hbr-color-accent-h-weak)">{"Beta"}</hbr-tag>

            {/* Right action buttons */}
            <div className="hbr-css__layout-justify-self-end">
                <div className="hbr-css__layout-row-3xs">

                    {/* Close button */}
                    <hbr-tooltip content="Close" placement="bottom">
                        <hbr-button
                            onClick={handleCloseClick}
                            variant="text"
                            size="small"
                            sentiment="neutral"
                        >
                            <hbr-icon name="x" sentiment="neutral" size="16px" />
                        </hbr-button>
                    </hbr-tooltip>
                </div>
            </div>
        </div>
    );
}

Header.propTypes = {
    handleCloseClick: PropTypes.func,
    handleMenuButtonClick: PropTypes.func,
    collapsed: PropTypes.bool
}

export default Header;