import React, { useState, useEffect, useRef } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import _ from "lodash";

//utils
import { displayDateTimeSeconds } from "../../../../utils/displayTime";
import { getKeyValueStyle } from "../../../../utils/common";

//config
import apiService from "../../../../config/api-config";

//components
import NoDataAvailable from "../../../../common/NoDataAvailable";

const [
    HbrTabPanel,
    HbrInput,
    HbrIcon,
] = reactWrapper([
    "hbr-tab-panel", "hbr-input", "hbr-icon"
]);

const Task = ({ name }) => {
    const [data, setData] = useState();
    const [timeNow, setTimeNow] = useState(new Date());

    const dataRef = useRef({
        active: [],
        completed: [],
        error: null
    })

    const refresh = () => {
        setTimeNow(new Date());
    }

    const getData = async () => {
        const result = await apiService.getTaskSummary();

        if (result.errorObject instanceof Object === false) {
            dataRef.current.active = result.data.data.filter(d => d.status !== "done")
            dataRef.current.completed = result.data.data.filter(d => d.status === "done")

            if (name === "active") {
                setData(dataRef.current.active);
            } else {
                setData(dataRef.current.completed)
            }
        }


    }

    useEffect(() => {
        getData();
    }, [timeNow])

    const onInput = (e) => {
        const currentValue = e.currentTarget.value;
        if (!_.isEmpty(currentValue)) {
            setData(dataRef.current[name].filter((value) => {
                return (
                    value.name?.toString().toLowerCase().includes(currentValue) ||
                    value.userSessionUserName?.toString().toLowerCase().includes(currentValue) ||
                    value.userSessionIP?.toString().toLowerCase().includes(currentValue) ||
                    value.tenantName?.toString().toLowerCase().includes(currentValue) ||
                    value.total?.toString().toLowerCase().includes(currentValue)
                )
            })
            );
        } else {
            setData(dataRef.current[name])
        }
    }

    const onRefreshClick = () => {
        refresh();
    }

    const removeTask = (e) => {
        setData(data.filter((value) => value.startTime.toString() !== e.currentTarget.id));
    }

    return (
        <HbrTabPanel name={name}>
            <div className="search-field">
                <div className="hbr-css__layout-col-md">
                    <HbrInput label="" placeholder="Search" size="medium" value="" clearable={true}
                        className="hbr-css__form-section-md"
                        data-testid="activated"
                        onHbr-input={onInput}
                    >
                        <HbrIcon slot="prefix" name="magnifying-glass" size="16px" staticColors={true} />
                        <HbrIcon slot="clear-icon" name="hbr-delete-content" size="16px" staticColors={true}></HbrIcon>
                    </HbrInput>
                </div>
            </div>
            <div className="task-one-row">
                <div className="key-value">
                    <div className="label hbr-type-body2">{"Last updated: "}</div>
                    <div className="time hbr-type-body2">{displayDateTimeSeconds(timeNow)}</div>
                </div>
                <div id="refresh-icon"><HbrIcon sentiment="info" name="refresh" size="20px" onClick={onRefreshClick}></HbrIcon></div>
            </div>
            <div className="task-sort">
                <div className="label hbr-type-body2">{"Sort by: "}</div>
                <div className="time hbr-type-body2">{"Start time "}</div>
            </div>
            {data?.length === 0 && <div className="no-data">
                <NoDataAvailable />
            </div>}
            <div className="sorted-tasks">
                {data && data.map((task, key) =>
                    <div className="task" key={key}>
                        {<div className="task-one-row">
                            <div className="key-value">
                                {Object.keys(task.count)[0] === "Success" ?
                                    <HbrIcon name="status-ok" sentiment="success" size="18px"></HbrIcon> :
                                    <HbrIcon name="status-critical" sentiment="danger" size="18px"></HbrIcon>
                                }
                                <div className="task-title hbr-type-body2">{`${task.name} (Total ${task.total})`}</div>
                            </div>
                            <div className="remove-task" id={task.startTime} onClick={removeTask}><HbrIcon name="x" size="12px"></HbrIcon></div>
                        </div>}
                        {getKeyValueStyle(`${Object.keys(task.count)[0]}:`, task.total)}
                        {getKeyValueStyle("Start: ", displayDateTimeSeconds(task.startTime))}
                        {getKeyValueStyle("End: ", displayDateTimeSeconds(task.endTime))}
                        <div className="task-from">
                            {getKeyValueStyle("By: ", task.userSessionUserName)}
                            {getKeyValueStyle("From: ", task.userSessionIP)}
                            {getKeyValueStyle("Tenant:", task.tenantName)}
                        </div>
                    </div>
                )}
            </div>
        </HbrTabPanel>
    )
}

Task.propTypes = {
    name: PropTypes.string,
    updateTabsLength: PropTypes.func
}

export default Task;