import React from "react";
/* eslint-disable react/prop-types */

import {
  setCtrlTimeReportFilter,
  setOverlayId,
  setCustomReportTime,
  setWorkflow
} from "./actions";
import reduxContext from "./reducer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapDispatchToProps = {
  setOverlayId,
  setCtrlTimeReportFilter,
  setCustomReportTime,
  setWorkflow
};

const mapStateToProps = state => {
  return {
    globalReport: state.vanalytics.app.globalReport
  };
};

const AppHocReports = WrappedComponent => {
  class PageComponent extends React.Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          setOverlayId={this.props.setOverlayId}
          setCtrlTimeReportFilter={this.props.setCtrlTimeReportFilter}
          setCustomReportTime={this.props.setCustomReportTime}
          setWorkflow={this.props.setWorkflow}
        />
      );
    }
  }

  return reduxContext.withProvider(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(PageComponent))
  );
};

export default AppHocReports;
