export default {
  //TimeFilter
  timeFilter1Hour: "1 Hour",
  timeFilter1HourText: "the previous 1 hour",
  timeFilter1HourLegend: "1 hr",
  timeFilter1HourTooltip: "previous 1 hour",

  timeFilter3Hours: "3 Hours",
  timeFilter3HoursText: "the previous 3 hours",
  timeFilter3HoursLegend: "3 hr",
  timeFilter3HoursTooltip: "previous 3 hours",

  timeFilter6Hours: "6 Hours",
  timeFilter6HoursText: "the previous 6 hours",
  timeFilter6HoursLegend: "6 hr",
  timeFilter6HoursTooltip: "previous 6 hours",

  timeFilter12Hours: "12 Hours",
  timeFilter12HoursText: "the previous 12 hours",
  timeFilter12HoursLegend: "12 hr",
  timeFilter12HoursTooltip: "previous 12 hours",

  timeFilter24Hours: "24 Hours",
  timeFilter24HoursText: "the previous day",
  timeFilter24HoursLegend: "Day",
  timeFilter24HoursTooltip: "previous day",

  timeFilter7Days: "7 Days",
  timeFilter7DaysText: "the previous week",
  timeFilter7DaysLegend: "Week",
  timeFilter7DaysTooltip: "previous week",

  timeFilter1Month: "1 Month",
  timeFilter1MonthText: "the previous month",
  timeFilter1MonthLegend: "Month",
  timeFilter1MonthTooltip: "previous month",

  timeFilterCustom: "Custom",
  timeFilterCustomText: "the previous custom date range",
  timeFilterCustomLegend: "Custom",
  timeFilterCustomTooltip: "previous custom date range",
  timeFilterCustomApply: "Apply",
  timeFilterCustomClear: "Clear",
  timeFilterCustomCompareWith: "The change values below are compared with: ",

  //SiteDropDown
  siteDropDownEditReport: "Edit the Report",

  //AppHOC
  appHocAllSites: "All Sites",

  //AppHeader
  appHeaderPredictiveLabel: "Analytics · Predictive Networks",

  //Controls
  refresh: "Refresh",
  fullScreen: "Full Screen",
  captureScreen: "Capture Screen",
  admin: "admin",
  signOut: "Sign Out",
  productLabel: "Catalyst SD-WAN",

  //Pre & Post login notification banner
  information: "information",
  warning: "warning",
  //MegaMenu
  megaMenuFabricLabel: "Fabric",
  megaMenuFabricsLabel: "Fabrics",
  megaMenuSitesLabel: "Sites",
  megaMenuCircuits: "Circuits",
  megaMenuViewFabricLabel: "View All Fabrics",
  megaMenuOperatedLabel: "Operated As",
  megaMenuSearchSite: "Search",
  megaMenuSearchGlobal: "Global",
  emptyData: "No data available",
  megaMenuSdwan: "SD-WAN Manager",
  headerLoggedLabel: "LOGGED IN AS",
  headerRoleAs: "ROLE AS",
  headerMyProfile: "My Profile",
  headerSDRouting: "SD-Routing",
  headerLogOutLabel: "Log Out",
  multiTenantProvider: "Provider",
  noSelection: "No selection",
  help: "Help",
  helpOnlineDoc: "Online Documentation",
  helpSpotlight: "Spotlight",
  helpUserGuide: "SD-WAN User Guide",
  helpAsk: "Ask Cisco Networking",
  helpAbout: "About",
  helpReport: "Report Issue (internal)",
  helpModalTitle: "Cisco Catalyst SD-WAN",
  helpModalPlatform: "Platform version:",
  helpModalVersion: "Application version:",
  helpModalServer: "Server:",
  helpModalTime: "Timestamp:",
  helpModalZone: "Time zone:",
  helpModalClose: "Close",
  helpNoContent: "No content available",
  multiTenant: "Tenants",
  selectTenantLabel: "Select a tenant",
  megaMenuPredictiveNetworks: "Predictive Networks",
  threatIntelligence: "Threat Intelligence",
  trafficLogs: "Logs",

  //header icons
  taskLabel: "Tasks",
  activeTab: "Active",
  completedTab: "Completed",
  notificationLabel: "Notifications",
  notificationTitle: "Device",
  notificationViewAll: "View all",
  notificationWani: "Predictive Networks",

  //ai assistant chat
  mainTitle: "What do you want to ask today?",
  mainSubTitleExplanation: "Choose from a suggestion below or use the text field to ask a question. I have limitations and won't always get it right, but your feedback will help me improve.",
  formDisclosure: "The AI Assistant may display inaccurate information. Make sure to verify the response.",
  formPlaceholder: "Ask the AI Assistant a question",
  modalCancel: "Cancel",
  modalProceed: "Proceed",
  modalText: "Please enable Cloud Services to start using the feature.",
  modalFeatureNotAvailable: "The AI assistant is currently in a limited beta phase and will soon be offered to a more extensive customer base.",
  cornerTitle: "AI Assistant",
  unable: "Unable to process AI assistant response.",
  generating: "Generating",
  chatSubmit: "Submit",
  feedbackNotUseful: "Not useful",
  feedbackIncorrect: "Incorrect",
  feedbackOther: "Other",
  chartfeedback: "Feedback: ",

};
