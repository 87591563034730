import React from "react";
import { PropTypes } from "prop-types";
import { shortDisplayTime } from "../../utils/displayTime"

const CustomTimeStamp = ({ prevTime,  currentTime}) => {

  return (
    <>
      <div className="timestamp">
        <span className="text">{shortDisplayTime(prevTime)}</span>
        <span className="text">{" - "}</span>
        <span className="text">{shortDisplayTime(currentTime)}</span>
      </div>
    </>
  );
};


CustomTimeStamp.propTypes = {
  prevTime: PropTypes.string,
  currentTime: PropTypes.string
};

export default CustomTimeStamp;
