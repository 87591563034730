import React, { useState, useEffect } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";

import i18n from "amdi18n-loader!../../nls/i18n";

//style
import css from "../../genericMagneticStyle.less";

//components
import Task from "./Task";

import apiService from "../../../config/api-config";

const [
    HbrDrawer,
    HbrTabGroup,
    HbrTab,
    HbrBadge
] = reactWrapper([
    "hbr-drawer", "hbr-tab-group", "hbr-tab", "hbr-badge"
]);

const TaskWidget = ({ closeTask }) => {
    const [activelen, setactivelen] = useState(0);
    const [completedLen, setcompletedLen] = useState(0)
    const [isLoading, setIsLoading] = useState(false);

    const _onClose = () => {
        closeTask();
    };

    const getData = async () => {
        const result = await apiService.getTaskSummary();

        if (result.errorObject instanceof Object === false) {
            setactivelen(result.data.data.filter(d => d.status !== "done").length)
            setcompletedLen(result.data.data.filter(d => d.status === "done").length)
        }

        setIsLoading(true);
    }

    useEffect(() => {
        getData();
    }, [])

    if (!isLoading) return <></>

    return (
        <div className={css["task-icon-drawer"]}>
            <HbrDrawer
                label={i18n.taskLabel}
                open
                onHbr-hide={_onClose}
            >
                <div className="task-tabs">
                    <HbrTabGroup primary>
                        <HbrTab slot="nav" panel="active">
                            <HbrBadge slot="suffix" pill size="small">
                                {activelen}
                            </HbrBadge>
                            {i18n.activeTab}
                        </HbrTab>
                        <HbrTab slot="nav" panel="completed">
                            <HbrBadge slot="suffix" pill size="small">
                                {completedLen}
                            </HbrBadge>
                            {i18n.completedTab}
                        </HbrTab>
                        {isLoading && <Task name={"active"} />}
                        {isLoading && <Task name={"completed"} />}
                    </HbrTabGroup>
                </div>
            </HbrDrawer>
        </div>
    )
}

TaskWidget.propTypes = {
    closeTask: PropTypes.func
}

export default TaskWidget;