import i18nMessageBundle from "amdi18n-loader!../nls/i18n";
import { getCookie } from "../../serviceWorker";
const isUIconvergence = getCookie("cl-analytics");


export const convergeredConfig = {
  "1": {
    label: i18nMessageBundle.timeFilter1Hour,
    value: "1",
    text: i18nMessageBundle.timeFilter1HourText,
    legendText: i18nMessageBundle.timeFilter1HourLegend,
    toolTip: i18nMessageBundle.timeFilter1HourTooltip,
    additionalText:true
  },
  "3": {
    label: i18nMessageBundle.timeFilter3Hours,
    value: "3",
    text: i18nMessageBundle.timeFilter3HoursText,
    legendText: i18nMessageBundle.timeFilter3HoursLegend,
    toolTip: i18nMessageBundle.timeFilter3HoursTooltip,
    additionalText:true
  },
  "6": {
    label: i18nMessageBundle.timeFilter6Hours,
    value: "6",
    text: i18nMessageBundle.timeFilter6HoursText,
    legendText: i18nMessageBundle.timeFilter6HoursLegend,
    toolTip: i18nMessageBundle.timeFilter6HoursTooltip,
    additionalText: true
  },
}

const extraConfig = isUIconvergence || process.env.REACT_APP_IS_MINUTELY === "true" ? convergeredConfig : {};
const timeFilterConfig = {
  ...extraConfig,
  "12": {
    label: i18nMessageBundle.timeFilter12Hours,
    value: "12",
    text: i18nMessageBundle.timeFilter12HoursText,
    legendText: i18nMessageBundle.timeFilter12HoursLegend,
    toolTip: i18nMessageBundle.timeFilter12HoursTooltip,
    additionalText: false
  },
  "24": {
    label: i18nMessageBundle.timeFilter24Hours,
    value: "24",
    text: i18nMessageBundle.timeFilter24HoursText,
    legendText: i18nMessageBundle.timeFilter24HoursLegend,
    toolTip: i18nMessageBundle.timeFilter24HoursTooltip,
    additionalText: false
  },
  "168": {
    label: i18nMessageBundle.timeFilter7Days,
    value: "168",
    text: i18nMessageBundle.timeFilter7DaysText,
    legendText: i18nMessageBundle.timeFilter7DaysLegend,
    toolTip: i18nMessageBundle.timeFilter7DaysTooltip,
    additionalText: false
  },
  "720": {
    label: i18nMessageBundle.timeFilter1Month,
    value: "720",
    text: i18nMessageBundle.timeFilter1MonthText,
    legendText: i18nMessageBundle.timeFilter1MonthLegend,
    toolTip: i18nMessageBundle.timeFilter1MonthTooltip,
    additionalText: false
  },
  "Custom": {
    label: i18nMessageBundle.timeFilterCustom,
    value: "Custom",
    text: i18nMessageBundle.timeFilterCustomText,
    legendText: i18nMessageBundle.timeFilterCustomLegend,
    toolTip: i18nMessageBundle.timeFilterCustomTooltip,
    additionalText: false
  }
};
export default timeFilterConfig;