import { PropTypes } from "prop-types";

//i18n files
import i18n from "amdi18n-loader!../../../nls/i18n";

const GeneratingMessages = ({ selectedThread }) => {
    return (
        <div className="hbr-css__chat-messages">
            <div className="selected-thread">{selectedThread.error ? selectedThread.msg : selectedThread}</div>
            <hbr-chat-message variant="assistant">
                {selectedThread.error ?
                    <div>{i18n.unable}</div> :
                    <div className="hbr-css__layout-row-xs">
                        <hbr-spinner size="small">
                            <hbr-icon slot="icon" name="loader-gradient" static-colors />
                        </hbr-spinner>
                        <div className="hbr-css__text-ai-sm-bold">
                            {i18n.generating}
                        </div>
                    </div>
                }
            </hbr-chat-message>
        </div>
    );
}
GeneratingMessages.propTypes = {
    selectedThread: PropTypes.object
}
export default GeneratingMessages;