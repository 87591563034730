import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";

import i18n from "amdi18n-loader!../../../nls/i18n";

const [HbrModal, HbrButton] =
    reactWrapper(["hbr-modal", "hbr-button"]);

const AiFeedbackModal = ({ refFeedbackModal }) => {

    const onClickClose = () => {
        refFeedbackModal.current.element.hide();
    }

    return (
        <div>
            <HbrModal ref={refFeedbackModal} style={{ "--width": "380px" }}>
                <div>{i18n.modalFeatureNotAvailable}</div>
                <div slot="footer" className="hbr-css__layout-row-md hbr-css__layout-justify-end">
                    <HbrButton variant="text" onClick={onClickClose}>
                        {i18n.modalCancel}
                    </HbrButton>
                </div>
            </HbrModal>
        </div>
    )
}

AiFeedbackModal.propTypes = {
    refFeedbackModal: PropTypes.object
}
export default AiFeedbackModal;