export const request =
{
    "query":
    {
        "field": "host-name",
        "condition": "AND",
        "rules": [
            {
                "value": ["24"],
                "field": "entry_time",
                "type": "date",
                "operator": "last_n_hours"
            },
            {
                "field": "active",
                "operator": "in",
                "type": "boolean",
                "value": ["true"]
            },
            {
                "field": "acknowledged",
                "operator": "in",
                "type": "boolean",
                "value": ["false"]
            }
        ],
        "size": 1000
    }
}

export const countNotficationPayload = {
    "query":
    {
        "condition": "AND", "rules":
            [
                {
                    "value": ["24"],
                    "field": "entry_time",
                    "type": "date",
                    "operator": "last_n_hours"
                },
                {
                    "field": "active",
                    "operator": "in",
                    "type": "boolean",
                    "value": ["true"]
                },
                {
                    "field": "acknowledged",
                    "operator": "in",
                    "type": "boolean",
                    "value": ["false"]
                }
            ]
    }
}