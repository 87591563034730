import { PropTypes } from "prop-types";

import i18n from "amdi18n-loader!../../../nls/i18n";

const Placeholder = ({ suggestedQuestions, setDialogMessages }) => {

    return (
        <div>
            <div className="hbrchat-placeholder">
                <hbr-illustration
                    className="hbrchat-placeholder-hero"
                    name="lens-logo"
                    static-colors
                    width="173px"
                    height="160px"
                >
                    <div slot="heading" className="hbr-css__text-heading-xl-bold">
                        {i18n.mainTitle}
                    </div>
                    <div slot="body" className="hbr-css__text-primary-sm">
                        {i18n.mainSubTitleExplanation}
                    </div>
                </hbr-illustration>

                <div className="hbrchat-suggestions-container">
                    {suggestedQuestions.map((it) => (
                        <SuggestionCard
                            key={it.intent}
                            heading={it.intent}
                            description={it.description}
                            setDialogMessages={setDialogMessages}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

Placeholder.propTypes = {
    suggestedQuestions: PropTypes.array,
    setDialogMessages: PropTypes.func
}

const SuggestionCard = ({
    heading,
    description,
    messageConstructor,
    setDialogMessages,
}) => {
    if (!messageConstructor) {
        messageConstructor = ({ heading, description, message }) => {
            return message || `${heading} ${description}`;
        };
    }

    const handleClick = (e) => {
        setDialogMessages(e.currentTarget.id)
    }

    return (
        <div className="hbrchat-suggestion-card" id={heading} onClick={(e) => handleClick(e)}>
            <div className="hbr-css__text-heading-md-bold">{heading}</div>
            <div className="hbr-css__text-secondary-sm">{description}</div>
        </div>
    );
};

SuggestionCard.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    message: PropTypes.string,
    messageConstructor: PropTypes.object,
    setDialogMessages: PropTypes.func
}
export default Placeholder;
