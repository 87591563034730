import { useEffect, useRef, useCallback } from "react";
import { PropTypes } from "prop-types";
import { matchPath } from "react-router-dom";
import AppHOC from "../AppHOC";
import { useMount } from "../../utils/genericCommon";
import { getLocalStorageFlag } from "../../utils/common";
import apiService from "../../config/api-config";
import { BASE_URL } from "../../apis/apiConstants";

const SiteList = ({ location, globalFilter, setSites }) => {
  const mount = useMount();
  const dataRef = useRef({
    timestamp: 0,
    fabric: 0,
    loader: true,
    error: null,
    data: []
  });

  useEffect(() => {
    if (
      matchPath(location.pathname, { path: `${BASE_URL}/accounts` }) === null
    ) {
      const fabric = mount.initMount === true
        ? parseInt(getLocalStorageFlag("currentOverlay"))
        : parseInt(globalFilter.selectedOverlay);
      if (Number.isInteger(fabric)) {
        if (fabric !== dataRef.current.fabric || (
          dataRef.current.loader === false &&
          (dataRef.current.error !== null || dataRef.current.data.length === 0)
        )) {
          const timestamp = Date.now();
          Object.assign(
            dataRef.current,
            { timestamp, fabric, loader: true, error: null, data: [] }
          );
          setSites([]);
          loadData(timestamp);
        }
      }
    }
    mount.initMount = false;
  }, [globalFilter.selectedOverlay, globalFilter.currentTimeStamp]);

  const loadData = useCallback(async timestamp => {
    const res = await apiService.getSites();

    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        const resData = res.data.data;
        dataRef.current.data = resData;
        setSites(resData);
      }
      dataRef.current.loader = false;
    }
  }, []);

  return null;
};

SiteList.propTypes = {
  location: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  setSites: PropTypes.func.isRequired
};

export default AppHOC(SiteList);
