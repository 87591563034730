import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";

const [HbrMenuItem] = reactWrapper(["hbr-menu-item"]);

const MenuItemAside = ({ session, checked, setSelectedThread }) => {
    const handleMenuItemClick = (e) => {
        if (e.target.tagName === "HBR-MENU-ITEM") setSelectedThread({
            message: session.messages[1].content,
            query: session.chatSessionName,
            isOldSession: true
        });
    }
    return (
        <HbrMenuItem checked={checked} onClick={handleMenuItemClick}>
            <hbr-icon slot="prefix" name="session" size="16px" />
            {session.chatSessionName}
        </HbrMenuItem>
    );
}

MenuItemAside.propTypes = {
    session: PropTypes.string,
    checked: PropTypes.bool,
    setSelectedThread: PropTypes.func
}

const Aside = ({
    collapsed,
    setSelectedThread,
    handleNewThreadClick,
    sessions
}) => {

    return (
        <div slot="aside" className="hbr-css__layout-col-sm aside">
            <div className="hbrchat-aside-header ">
                {collapsed ?
                    <hbr-button
                        sentiment="ai"
                        onClick={handleNewThreadClick}
                        size="small"
                    >
                        <hbr-icon slot="prefix" name="plus"></hbr-icon>
                    </hbr-button> :
                    <hbr-button
                        sentiment="ai"
                        onClick={handleNewThreadClick}
                        size="small"
                    >
                        <hbr-icon slot="prefix" name="plus"></hbr-icon>
                        {"New Thread"}
                    </hbr-button>}
            </div>


            {!collapsed ? (
                <hbr-menu variant="context" class="hbr-css__chat-aside-menu">
                    {sessions.map((session, key) => (
                        <MenuItemAside
                            key={key}
                            session={session}
                            setSelectedThread={setSelectedThread}
                        />
                    ))}
                </hbr-menu>
            ) : null}
        </div>
    );
}

Aside.propTypes = {
    collapsed: PropTypes.bool,
    setSelectedThread: PropTypes.func,
    handleNewThreadClick: PropTypes.func,
    sessions: PropTypes.array
}

export default Aside;