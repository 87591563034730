/* eslint no-unused-vars : "off" */
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as axios from "./server-interceptor";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./global/styles/globalMagneticStyle.less";

ReactDOM.render(
  <div>
    <script>
      {top.location != self.location ? (parent.location = self.location) : ""}
    </script>
    <App />
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
