import React, { useMemo } from "react";
import { PropTypes } from "prop-types";
import { Redirect, useRouteMatch } from "react-router-dom";
import Unauthorized from "../../common/Unauthorized";
import MegaMenu from "../MegaMenu";
import GridContainerWorkflow from "../GridContainerWorkflow";
import GridContainer from "../GridContainer";
import { getLocalStorageFlag, isAdminRole } from "../../utils/common";
import { getCookie } from "../../serviceWorker";
import { routes } from "../../config/routeConfig";

const Authorization = ({ pageProps }) => {
  const match = useRouteMatch();

  const accessData = useMemo(() => {
    const result = {
      redirectToAccounts: false,
      unauthorized: false,
      sidebar: true
    };
    const currentOverlay = getLocalStorageFlag("currentOverlay");
    const clVmanageURI = getCookie("cl-vManageURI");
    const uiConvergence = getCookie("cl-analytics");
    if (
      !currentOverlay && !uiConvergence && !clVmanageURI
      && match.path !== routes.accounts.path
    )
      result.redirectToAccounts = true;
    else {
      for (const key in routes) {
        const route = routes[key];
        if (
          route.path === match.path
          || (Array.isArray(route.path) && route.path.includes(match.path))
        ) {
          if (route.access.admin === true) {
            let roles = localStorage.getItem("roles");
            if (roles) roles = JSON.parse(roles);
            result.unauthorized = isAdminRole(roles) === false;
          }
          result.sidebar = route.access.sidebar;
          break;
        }
      }
    }
    return result;
  }, []);

  return (
    <>
      {accessData.redirectToAccounts === true ? (
        <Redirect to={routes.accounts.path} />
      ) : accessData.unauthorized === true ? (
        <Unauthorized />
      ) : (
        < div id="main-content">
          {accessData.sidebar === true && (
            <MegaMenu pathToAccount={routes.accounts.path} />
          )}
          {pageProps.workflowContainer === true ? (
            <GridContainerWorkflow {...pageProps} />
          ) : (
            <GridContainer {...pageProps} />
          )}
        </div>
      )}
    </>
  );
};

Authorization.propTypes = {
  pageProps: PropTypes.object.isRequired
};

export default Authorization;
