import { PropTypes } from "prop-types";
import { useState } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//assets
import { ReactComponent as Up } from "../../../../assets/thumbs-up.svg";
import { ReactComponent as Down } from "../../../../assets/thumbs-down.svg";
import { ReactComponent as UpSuccess } from "../../../../assets/thumbs-up-success.svg";
import { ReactComponent as DownSuccess } from "../../../../assets/thumbs-down-success.svg";

//api config
import apiService from "../../../../config/api-config";

//i18n files
import i18n from "amdi18n-loader!../../../nls/i18n";

const [HbrMarkdown, HbrCheckbox, HbrCheckboxGroup, HbrTextarea, HbrButton] =
    reactWrapper(["hbr-markdown", "hbr-checkbox", "hbr-checkbox-group", "hbr-textarea", "hbr-button"]);

const meta = [
    { name: "not useful", label: i18n.feedbackNotUseful },
    { name: "incorrect", label: i18n.feedbackIncorrect },
    { name: "other", label: i18n.feedbackOther }
]

const ChatMessages = ({ selectedThread, chatSessionId, dialogId }) => {
    const [upSuccess, setUpSuccess] = useState(false);
    const [downSuccess, setDownSuccess] = useState(false);
    const [valueFeedback, setValueFeedback] = useState([]);
    const [text, setText] = useState("");
    const payload = {
        id: dialogId,
        vote: "false",
        chatSessionId: chatSessionId
    }

    const handleClick = (value, i) => {
        const names = meta.map(it => it.name);
        const checkedStatus = names.map(it => value.currentTarget.textContent.toLowerCase().includes(it));
        checkedStatus[i] = !checkedStatus[i];
        const newValue = [];
        checkedStatus.map((status, index) => {
            if (status) newValue.push(names[index])
        })
        setValueFeedback(newValue);
    }

    //save text in text area
    const oninput = (event) => {
        setText(event.target.value);
    }
    //on submit send feedback
    const onsubmit = async () => {
        Object.assign(payload, { comment: `${valueFeedback}, ${text}` });
        setDownSuccess(false);

        await apiService.sendFeedback(payload);
    }

    const updateUserFeedbackDown = async () => {
        setUpSuccess(false);
        setDownSuccess(true);
    }
    const updateUserFeedbackUp = async () => {
        setUpSuccess(true);
        setDownSuccess(false);

        await apiService.sendFeedback(payload);
    }

    return (
        <div className="hbr-css__chat-messages">
            <div className="selected-thread">{selectedThread.query}</div>
            <hbr-chat-message variant="assistant">
                <HbrMarkdown content={selectedThread.message} />
                {!selectedThread.isOldSession &&
                    <div className='loading'>
                        <div className="actions" >
                            <br />
                            {upSuccess ?
                                <UpSuccess
                                    onClick={updateUserFeedbackUp}
                                    slot="icon"
                                    className="thumb-icon"
                                /> :
                                <Up
                                    onClick={updateUserFeedbackUp}
                                    slot="icon"
                                    sentiment="success"
                                    className="thumb-icon"
                                />
                            }
                            {downSuccess ?
                                <DownSuccess
                                    onClick={updateUserFeedbackDown}
                                    slot="icon"
                                    className="thumb-icon"
                                /> :
                                <Down
                                    onClick={(e) => updateUserFeedbackDown(e)}
                                    slot="icon"
                                    className="thumb-icon"
                                />

                            }
                            {downSuccess &&
                                <HbrCheckboxGroup
                                    label={i18n.chartfeedback}
                                >
                                    {meta.map((item) => (
                                        <HbrCheckbox
                                            key={item.name}
                                            name={item.name}
                                            checked={valueFeedback.includes(item.name)}
                                            onHbr-click={(e) => handleClick(e)}
                                        >
                                            {item.label}
                                        </HbrCheckbox>
                                    ))
                                    }
                                </HbrCheckboxGroup>}
                            {downSuccess &&
                                <div>
                                    <HbrTextarea label="" style={{ width: "360px" }} value="" onHbr-input={(e) => oninput(e)}>
                                    </HbrTextarea>
                                    <div className="submit-feedback">
                                        <HbrButton
                                            sentiment="info"
                                            onClick={onsubmit}
                                        >
                                            {i18n.chatSubmit}
                                        </HbrButton>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>}
            </hbr-chat-message>
        </div>
    );
}

ChatMessages.propTypes = {
    selectedThread: PropTypes.object,
    chatSessionId: PropTypes.string,
    dialogId: PropTypes.string
}

export default ChatMessages