export const ACTIONS = {
  SET_OVERLAYS: "SET_OVERLAYS",
  SET_SELECTED_OVERLAY: "SET_SELECTED_OVERLAY",
  SET_SITES: "SET_SITES",
  SET_SELECTED_SITE: "SET_SELECTED_SITE",
  SET_CTRL_TIME_FILTER: "SET_CTRL_TIME_FILTER",
  SET_SIDE_BAR_TIME_FILTER: "SET_SIDE_BAR_TIME_FILTER",
  SET_CUSTOM_TIME: "SET_CUSTOM_TIME",
  SET_SIDE_BAR_CUSTOM_TIME: "SET_SIDE_BAR_CUSTOM_TIME",
  SET_ACCOUNTS: "SET_ACCOUNTS",
  SET_SELECTED_OVERLAY_ROLES: "SET_SELECTED_OVERLAY_ROLES",
  SET_REFRESH: "SET_REFRESH",
  SET_ALTO_PROPS: "SET_ALTO_PROPS",
  SET_SUPPORT_ROLE: "SET_SUPPORT_ROLE",
  SET_SSO_LOGOUT: "SET_SSO_LOGOUT",
  SET_BREADCRUMBS: "SET_BREADCRUMBS",
  setCircuitsLoader: "app/circuits/loader",
  setCircuitsData: "app/circuits/data",
  SET_SITES_TABLE_AVAILABILITY: "SET_SITES_TABLE_AVAILABILITY",
  SET_OVERVIEW_ERROR: "SET_OVERVIEW_ERROR",
  SET_OVERVIEW_SITE_LOADER: "SET_OVERVIEW_SITE_LOADER",
  SET_DEVICE_SITE: "SET_DEVICE_SITE",
  SET_DEVICE_OVERVIEW_ERROR: "SET_DEVICE_OVERVIEW_ERROR",
  SET_OVERVIEW_DEVICE_LOADER: "SET_OVERVIEW_DEVICE_LOADER",
  SET_AVAILABLE_FEATURE_FLAGS: "SET_AVAILABLE_FEATURE_FLAGS",
  SET_APPLICATIONDATA_API_STATUS: "SET_APPLICATIONDATA_API_STATUS",
  SET_APP_FAMILY_SLA: "SET_APP_FAMILY_SLA",
  SET_APP_DATA_WITH_FAMILY_SLA: "SET_APP_DATA_WITH_FAMILY_SLA",
  SET_APP_DATA_SELECTED: "SET_APP_DATA_SELECTED",
  SET_APP_DATA_SELECTED_LEGEND: "SET_APP_DATA_SELECTED_LEGEND",
  SET_POST_LOGIN_NOTIFICATIONS: "SET_POST_LOGIN_NOTIFICATIONS",
  SET_PREV_APP_DATA_SELECTED: "SET_PREV_APP_DATA_SELECTED",
  SET_OVR_RELOAD: "SET_OVR_RELOAD",
  SET_APP_DATA_SELECTED_DEFAULT: "SET_APP_DATA_SELECTED_DEFAULT",
  SET_TREND_ANALY_FILTER: "SET_TREND_ANALY_FILTER",
  SET_SIDEBAR_TABLE_DATA: "SET_SIDEBAR_TABLE_DATA",
  SET_ANOMALY_REFRESH: "SET_ANOMALY_REFRESH",
  SET_SDWAN_SITE_SUMMARY: "SET_SDWAN_SITE_SUMMARY",
  SET_SDWAN_SITE_SUMMARY_ERROR: "SET_SDWAN_SITE_SUMMARY_ERROR",
  SET_SDWAN_SITE_SUMMARY_LOADER: "SET_SDWAN_SITE_SUMMARY_LOADER",
  SET_TALOS_SITES: "SET_TALOS_SITES",
  SET_TALOS_ERROR: "SET_TALOS_ERROR",
  SET_TALOS_LOADER: "SET_TALOS_LOADER",
  SET_KPI_REFRESH: "SET_KPI_REFRESH",
  SET_PATH_ANALYTICS_TAB: "SET_PATH_ANALYTICS_TAB",
  SET_MENU_STATE: "SET_MENU_STATE",
  SET_BANDWIDTH_CHART_DATA: "SET_BANDWIDTH_CHART_DATA",
  BW_API_STATUS: "BW_API_STATUS",
  GET_SELECTED_CIRCUITS_LIST: "GET_SELECTED_CIRCUITS_LIST",
  SET_CIRCUITS_OBJECTLIST: "SET_CIRCUITS_OBJECTLIST",
  HEATMAP_API_STATUS: "HEATMAP_API_STATUS",
  GET_SELECTED_TREND_CIRCUITS_LIST: "GET_SELECTED_TREND_CIRCUITS_LIST",
  RESET_HEATMAP_AVAIL_CHANGE: "RESET_HEATMAP_AVAIL_CHANGE",
  SET_TREND_CIRCUITS_AVAIL: "SET_TREND_CIRCUITS_AVAIL",
  GET_BANDWIDTH_FORECAST_CIRCUIT: "GET_BANDWIDTH_FORECAST_CIRCUIT",
  TIMEFRAME_APP_FLOW: "TIMEFRAME_APP_FLOW",
  SET_TOGGLE: "SET_TOGGLE"
};

export const setPathAnalyticsTab = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_PATH_ANALYTICS_TAB,
    payload: payload
  });
};

export const setSdwanSiteSummary = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_SDWAN_SITE_SUMMARY,
    payload: payload
  });
};

export const setSdwanSiteSummaryError = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_SDWAN_SITE_SUMMARY_ERROR,
    payload: payload
  });
};

export const setSdwanSiteSummaryLoader = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_SDWAN_SITE_SUMMARY_LOADER,
    payload: payload
  });
};

export const setTalosSites = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_TALOS_SITES,
    payload: payload
  });
};

export const setTalosError = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_TALOS_ERROR,
    payload: payload
  });
};

export const setTalosLoader = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_TALOS_LOADER,
    payload: payload
  });
};

export const setKpiRefresh = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_KPI_REFRESH,
    payload: payload
  });
};

export const setAnomalyRefresh = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_ANOMALY_REFRESH,
    payload: payload
  });
};

export const setSsoLogoutCookie = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_SSO_LOGOUT,
    payload: payload
  });
};

export const setSitesTableAvailability = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_SITES_TABLE_AVAILABILITY,
    payload: payload
  });
};

export const setOverviewSiteLoader = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_OVERVIEW_SITE_LOADER,
    payload: payload
  });
};

export const setOverviewDeviceLoader = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_OVERVIEW_DEVICE_LOADER,
    payload: payload
  });
};

export const setOverviewError = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_OVERVIEW_ERROR,
    payload: payload
  });
};

export const setDeviceOverviewError = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_DEVICE_OVERVIEW_ERROR,
    payload: payload
  });
};

export const saveDeviceSiteData = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_DEVICE_SITE,
    payload: payload
  });
};

export const setOverlays = overlays => ({
  type: ACTIONS.SET_OVERLAYS,
  payload: overlays
});

export const setSelectedOverlay = (overlayId, overlayName) => ({
  type: ACTIONS.SET_SELECTED_OVERLAY,
  payload: {
    overlayId,
    overlayName
  }
});

export const setBreadCrumbs = breadCrumbsAry => ({
  type: ACTIONS.SET_BREADCRUMBS,
  payload: breadCrumbsAry
});

export const setSites = sites => ({
  type: ACTIONS.SET_SITES,
  payload: sites
});

export const setSelectedSite = site => ({
  type: ACTIONS.SET_SELECTED_SITE,
  payload: site
});

export const setCtrlTimeFilter = (
  time_period,
  timePeriodSelected
) => dispatch => {
  dispatch({
    type: ACTIONS.SET_CTRL_TIME_FILTER,
    payload: {
      time_period,
      timePeriodSelected
    }
  });
};

export const setCustomTime = time => ({
  type: ACTIONS.SET_CUSTOM_TIME,
  payload: time
});

export const setSideBarTimeFilter = (
  time_period,
  timePeriodSelected
) => dispatch => {
  dispatch({
    type: ACTIONS.SET_SIDE_BAR_TIME_FILTER,
    payload: {
      time_period,
      timePeriodSelected
    }
  });
};
export const setRefreshScreen = currentTimeStamp => dispatch => {
  dispatch({
    type: ACTIONS.SET_REFRESH,
    payload: currentTimeStamp
  });
};
export const setSideBarCustomTime = id => ({
  type: ACTIONS.SET_SIDE_BAR_CUSTOM_TIME,
  payload: id
});

export const setAccounts = accounts => ({
  type: ACTIONS.SET_ACCOUNTS,
  payload: accounts
});

export const setSelectedOverlayRoles = role => ({
  type: ACTIONS.SET_SELECTED_OVERLAY_ROLES,
  payload: role
});

export const setAltoProps = altoProps => ({
  type: ACTIONS.SET_ALTO_PROPS,
  payload: altoProps
});

export const setSupportRole = isSupportRole => ({
  type: ACTIONS.SET_SUPPORT_ROLE,
  payload: isSupportRole
});

export const setCircuitsLoader = payload => ({
  type: ACTIONS.setCircuitsLoader,
  payload
});

export const setCircuitsData = payload => ({
  type: ACTIONS.setCircuitsData,
  payload
});

export const setAvailableFeatureFlags = payload => ({
  type: ACTIONS.SET_AVAILABLE_FEATURE_FLAGS,
  payload
});
export const setAggregateAppData = aggregate_applications => dispatch => {
  dispatch({
    type: ACTIONS.SET_APPLICATIONDATA_API_STATUS,
    payload: aggregate_applications
  });
};

export const setAppFamilySLA = applications_sla => dispatch => {
  dispatch({
    type: ACTIONS.SET_APP_FAMILY_SLA,
    payload: applications_sla
  });
};

export const setAppsWithFamilySLA = applications_sla => dispatch => {
  dispatch({
    type: ACTIONS.SET_APP_DATA_WITH_FAMILY_SLA,
    payload: applications_sla
  });
};

export const setSelectedAppList = applications_sla => dispatch => {
  dispatch({
    type: ACTIONS.SET_APP_DATA_SELECTED,
    payload: applications_sla
  });
};

export const setSelectedAppListLegend = applications_sla => dispatch => {
  dispatch({
    type: ACTIONS.SET_APP_DATA_SELECTED_LEGEND,
    payload: applications_sla
  });
};

export const setPostLoginNotifications = payload => ({
  type: ACTIONS.SET_POST_LOGIN_NOTIFICATIONS,
  payload
});

export const setMenuState = payload => ({
  type: ACTIONS.SET_MENU_STATE,
  payload
});

export const setPrevSelectedAppList = applications_sla => dispatch => {
  dispatch({
    type: ACTIONS.SET_PREV_APP_DATA_SELECTED,
    payload: applications_sla
  });
};
export const setOverlayReload = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_OVR_RELOAD,
    payload: payload
  });
};

export const setSelectedAppListDefault = applications_sla => dispatch => {
  dispatch({
    type: ACTIONS.SET_APP_DATA_SELECTED_DEFAULT,
    payload: applications_sla
  });
};

export const setTrendAnalysisFilter = (payload) => (dispatch) => {
  dispatch({
    type: ACTIONS.SET_TREND_ANALY_FILTER,
    payload: payload,
  });
};

export const setSidebarTableData = (payload) => (dispatch) => {
  dispatch({
    type: ACTIONS.SET_SIDEBAR_TABLE_DATA,
    payload: payload,
  });
};

export const setBandwidthCircuitsData = (payload) => (dispatch) => {
  dispatch({
    type: ACTIONS.SET_BANDWIDTH_CHART_DATA,
    payload: payload,
  });
};

export const setSelectedCircuits = (payload) => (dispatch) => {
  dispatch({
    type: ACTIONS.GET_SELECTED_CIRCUITS_LIST,
    payload: payload,
  });
};
export const setSelectedCircuitsObject = (payload) => (dispatch) => {
  dispatch({
    type: ACTIONS.SET_CIRCUITS_OBJECTLIST,
    payload: payload,
  });
};
export const setbandWidthWidget = (payload) => (dispatch) => {
  dispatch({
    type: ACTIONS.BW_API_STATUS,
    payload: payload,
  });
};

export const heatMapApiStatus = payload => dispatch => {
  dispatch({
    type: ACTIONS.HEATMAP_API_STATUS,
    payload: payload
  });
};


export const selectedTrendCircuits = trendCircuitsList => dispatch => {
  dispatch({
    type: ACTIONS.GET_SELECTED_TREND_CIRCUITS_LIST,
    payload: trendCircuitsList
  });
};

export const resetHeatMapAvail = empty => dispatch => {
  dispatch({
    type: ACTIONS.RESET_HEATMAP_AVAIL_CHANGE,
    payload: empty
  });
};

export const setTrendAvail = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_TREND_CIRCUITS_AVAIL,
    payload: payload.data
  });
};

export const setBandwidthForecastCircuit = bandwidthProps => dispatch => {
  dispatch({
    type: ACTIONS.GET_BANDWIDTH_FORECAST_CIRCUIT,
    payload: bandwidthProps
  });
};

export const setTimeframeFlowsWidget = (payload) => (dispatch) => {
  dispatch({
    type: ACTIONS.TIMEFRAME_APP_FLOW,
    payload: payload,
  })
};

export const setPageToggle = selectedToggle => dispatch => {
  dispatch({
    type: ACTIONS.SET_TOGGLE,
    payload: selectedToggle
  });
};